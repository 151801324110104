import styled, { css } from 'styled-components/macro';
import { Device } from 'shared/theme';

export const Content = styled.main<{ noRightPadding?: boolean, isAsideMobileType?: boolean, pathname: string }>`
  width: auto;
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  padding-right: ${window.location.pathname === '/transfer' || window.location.pathname === '/transactions' ? '0' : '30px'};
  overflow-anchor: none;
  align-items: flex-start;
  max-width: 1615px;

  @media (max-width: 1675px) {
    ${(prop) =>
    prop.isAsideMobileType &&
    css`
      overflow: auto;
    `}
  }

  ${(prop) =>
    prop.noRightPadding &&
    css`
      padding: 0;
    `}

  @media (max-width: 1800px) {
    ${(prop) =>
    !prop.isAsideMobileType &&
    css`
      overflow: auto;
    `}

    &.helpdesk {
      overflow: unset;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  align-items: stretch;
  max-width: ${window.location.pathname === '/transfer' ? 'unset' : '1230px'};
  flex-wrap: nowrap;
  position: relative;

  @media ${Device.laptopM} {
    max-width: 1800px;
  }

  @media (max-width: 1800px) {
    padding-left: 0;
  }
`;
