import { useContext, useEffect, useCallback } from 'react';
import AppContext from 'shared/contexts/AppContext';
import { useIsMobile } from 'shared/hooks/useIsMobile';
import * as S from '../styled';
import {
  AutoSubmit,
  Checkbox,
  FilterContent,
  Label,
  LabelWrap,
} from 'shared/components';
import { FlatCurrencySelector } from '../components/FiatCurrencySelector';
import { Form, Formik } from 'formik';
import { TransferContext } from 'features/Transfer/context/TransferContext';
import { TransferFiltersType } from 'features/Transfer/types';
import { GET_DEFAULT_FILTERS } from 'features/Transfer/constants';
import { CurrencyType } from 'shared/types';

import { DEBOUNCE_DELAY } from 'features/Transfer/constants';

import { debounce } from 'lodash';

export const TransferFilters = () => {
  const { transactionStatus, isConfirming, appSettings, setCurrentAccNode } =
    useContext(AppContext);
  const {
    filters,
    setFilters,
    chosenCurrency,
    from,
    to,
    changeCurrency,
    selectAllCurrencies,
    handleToggleCurrency,
    isCurrencyDropdownOpen,
    useGetWalletsFrom,
    useGetWalletsTo,
    setFiltersDebounceLoading,
    handleFilterSearch,
  } = useContext(TransferContext);
  const [isMobile, isAsideButtonChecked, handleAsideSize] = useIsMobile();
  const DEFAULT_FILTERS = GET_DEFAULT_FILTERS(appSettings);

  const filtersCount = filters?.currencies?.length || 0;

  const { refetch: getWalletsFrom } = useGetWalletsFrom();
  const { refetch: getWalletsTo } = useGetWalletsTo(from?.wallet?.currency);

  const debouncedGetWalletsFrom = useCallback(
    debounce(() => getWalletsFrom(), DEBOUNCE_DELAY),
    [getWalletsFrom],
  );

  const debouncedGetWalletsTo = useCallback(
    debounce(() => getWalletsTo(), DEBOUNCE_DELAY),
    [getWalletsTo],
  );

  useEffect(() => {
    debouncedGetWalletsFrom();
    debouncedGetWalletsTo();
  }, [filters]);

  return (
    <S.Filters
      isMobile={isMobile}
      isAsideButtonChecked={isAsideButtonChecked}
      style={{
        zIndex: transactionStatus || isConfirming ? '-1' : '1',
      }}
    >
      {isMobile && (
        <S.FiltersData onClick={handleAsideSize}>
          <S.FiltersCounter>{filtersCount}</S.FiltersCounter>
          <S.FiltersIcon />
        </S.FiltersData>
      )}
      <S.ResetButton
        isMobile={isMobile}
        onClick={() => {
          setFiltersDebounceLoading('all');
          changeCurrency('');
          setFilters(DEFAULT_FILTERS);
          handleFilterSearch('from', '');
          handleFilterSearch('to', '');
        }}
      >
        Reset to Default View
      </S.ResetButton>
      <S.Filter isMobile={isMobile}>
        <FilterContent noPadding>
          <Formik
            initialValues={{
              isHideSmallBalances: filters.isHideSmallBalances,
            }}
            onSubmit={(values, { setSubmitting }) => {
              setFilters((filters: TransferFiltersType) => ({
                ...filters,
                ...values,
              }));
              setTimeout(() => setSubmitting(false), 0);
            }}
          >
            {() => {
              const isDisabled =
                Boolean(chosenCurrency) &&
                Boolean(from?.wallet) &&
                Boolean(to?.wallet);

              return (
                <Form>
                  <AutoSubmit />
                  <div>
                    <S.FilterSmallBalancesLabel isDisabled={isDisabled}>
                      <Checkbox
                        type="checkbox"
                        name="isHideSmallBalances"
                        disabled={isDisabled}
                        checked={filters.isHideSmallBalances}
                        onChange={(e: any) => {
                          setFiltersDebounceLoading('all');
                          setFilters((filters: TransferFiltersType) => ({
                            ...filters,
                            isHideSmallBalances: e.target.checked,
                          }));
                          // @ts-ignore
                          setTimeout(() => {
                            setCurrentAccNode((node: any) => {
                              node?.scrollIntoView({
                                inline: 'center',
                                block: 'center',
                                behavior: 'smooth',
                              });
                              return node;
                            });
                          }, 0);
                        }}
                      />
                      <span>Hide Small Balances</span>
                    </S.FilterSmallBalancesLabel>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </FilterContent>
      </S.Filter>
      <S.Filter isMobile={isMobile}>
        <LabelWrap>
          <Label>Coins</Label>
        </LabelWrap>
        <FlatCurrencySelector
          isMobile={isMobile}
          availableCurrencies={appSettings.currencies_primary}
          chosenCurrency={chosenCurrency as CurrencyType}
          filters={filters}
          handleChangeCurrency={changeCurrency}
          handleToggleCurrency={handleToggleCurrency}
          isCurrencyDropdownOpen={isCurrencyDropdownOpen}
          minValue={false}
        />
      </S.Filter>
      {!isMobile && <S.FilterHr />}
      <S.Filter style={{ marginBottom: '15px' }} isMobile={isMobile}>
        <FilterContent noPadding>
          <Formik
            initialValues={{ isSelectAll: filters.isSelectAll }}
            onSubmit={(values, { setSubmitting }) => {
              setFilters((filters: TransferFiltersType) => ({
                ...filters,
                ...values,
              }));
              setTimeout(() => setSubmitting(false), 0);
            }}
          >
            {() => {
              return (
                <Form>
                  <AutoSubmit />
                  <div>
                    <S.FilterSmallBalancesLabel>
                      <Checkbox
                        type="checkbox"
                        name="isSelectAll"
                        checked={filters.isSelectAll}
                        onChange={selectAllCurrencies}
                      />
                      <span>Select all</span>
                    </S.FilterSmallBalancesLabel>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </FilterContent>
      </S.Filter>
      <FlatCurrencySelector
        isMobile={isMobile}
        availableCurrencies={appSettings.currencies_secondary}
        chosenCurrency={chosenCurrency as CurrencyType}
        filters={filters}
        handleChangeCurrency={changeCurrency}
        handleToggleCurrency={handleToggleCurrency}
        isCurrencyDropdownOpen={isCurrencyDropdownOpen}
        minValue={true}
      />
      <S.AsideButton
        onClick={handleAsideSize}
        isMobile={isMobile}
        isAsideButtonChecked={isAsideButtonChecked}
      >
        <S.ArrowIconAside />
      </S.AsideButton>
    </S.Filters>
  );
};
