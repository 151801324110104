// @ts-nocheck
import { rest } from 'msw';
import {
  AuthSuccessType,
  ErrorType,
  TransactionProposalType,
  TransactionType,
  UserInfoType,
} from 'shared/types';
import { API_URL } from 'shared/constants/app';
import { DefaultRequestBodyType } from 'msw/lib/types/utils/handlers/requestHandler';

import { accounts, proposals, rates, settings, transactionProposal, transactions, whitelist } from './data';

let accountsRequestNum = 1;
let transactionsRequestNum = 1;
let transactionProposalsRequestNum = 1;

const generalError: ErrorType = {
  error: 'You do not have permission to perform this action.',
  error_details: [
    'First example of detailed error message.',
    'Example of another error in details.',
  ],
};

export const handlers = [
  rest.post(`${API_URL}/auth/token/`, (req, res, ctx) => {
    const result: AuthSuccessType = {
      token: '123',
      email: 'taras@kriptov.crypto',
      first_name: 'Taras',
      last_name: 'Kriptov',
      permissions: [
        'accounts.view_account',
        'accounts.change_account',
        'transactions.add_transaction',
        'transactions.view_transaction',
        'transaction_proposals.view_transactionproposal',
        'transaction_proposals.add_transactionproposal',
      ],
      has_hotp_secret: false,
      proposal_vote_weight: 1,
      period_limits: { WEEK: '1000000' },
      period_payments: { WEEK: '50000' },
    };

    return res(ctx.status(200), ctx.json(result), ctx.delay(300));
  }),

  rest.get(`${API_URL}/auth/userinfo/`, (req, res, ctx) => {
    const result: UserInfoType = {
      email: 'taras@kriptov.crypto',
      first_name: 'Taras',
      last_name: 'Kriptov',
      permissions: [
        'accounts.view_account',
        'accounts.change_account',
        'transactions.add_transaction',
        'transactions.view_transaction',
        'transaction_proposals.view_transactionproposal',
        'transaction_proposals.add_transactionproposal',
      ],
      has_hotp_secret: false,
      proposal_vote_weight: 1,
      period_limits: { WEEK: '10000' },
      period_payments: { WEEK: '5000' },
    };

    return res(ctx.status(200), ctx.json(result), ctx.delay(300));
  }),
  rest.post(`${API_URL}/auth/hotp/`, (req, res, ctx) => {
    const result: { secret?: string } = {
      secret:
        'otpauth://hotp/cryptotreasury:test@exan.tech?secret=6ufaghgaass2mv42y&issuer=cryptotreasury&counter=1',
    };

    return res(ctx.status(200), ctx.json(result), ctx.delay(300));
  }),
  rest.get(`${API_URL}/settings/`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(settings), ctx.delay(300));
  }),

  rest.get(`${API_URL}/accounts/`, (req, res, ctx) => {
    if (accountsRequestNum % 10 === 0) {
      return res(ctx.status(401), ctx.json({ error: 'The Token is expired' }));
    }
    accountsRequestNum++;

    return res(ctx.status(200), ctx.json(accounts), ctx.delay(300));
  }),

  rest.get(`${API_URL}/rates/`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(rates), ctx.delay(100));
  }),

  rest.get(`${API_URL}/transactions/`, (req, res, ctx) => {
    if (transactionsRequestNum % 10 === 0) {
      return res(ctx.status(401), ctx.json({ error: 'The Token is expired' }));
    }
    transactionsRequestNum++;

    return res(
      ctx.status(200),
      ctx.json({
        count: 1,
        next: '',
        previous: '',
        results: transactions,
      }),
      ctx.delay(300),
    );
  }),

  rest.get(`${API_URL}/transaction-proposals/`, (req, res, ctx) => {
    if (transactionProposalsRequestNum % 10 === 0) {
      return res(ctx.status(401), ctx.json({ error: 'The Token is expired' }));
    }
    transactionProposalsRequestNum++;

    return res(
      ctx.status(200),
      ctx.json({
        count: 1,
        next: '',
        previous: '',
        results: proposals,
      }),
      ctx.delay(300),
    );
  }),

  rest.patch<DefaultRequestBodyType & { [hint: string]: number }>(
    `${API_URL}/transaction-proposals/:proposalId`,
    (req, res, ctx) => {
      const { proposalId } = req.params;
      const { status } = req.body;

      const foundProposal = proposals.find(
        (item) => item.id === Number(proposalId),
      );

      if (foundProposal) {
        foundProposal.status = status;
      }

      if (!foundProposal) {
        const errorResponse = { error: 'Unknown error happened!' };

        return res(ctx.status(400), ctx.json(errorResponse), ctx.delay(500));
      }

      return res(ctx.status(201), ctx.json(foundProposal), ctx.delay(3000));
    },
  ),

  rest.get(`${API_URL}/whitelist/`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(whitelist), ctx.delay(300));
  }),

  rest.post<DefaultRequestBodyType & { [wallet_from: string]: number }>(
    `${API_URL}/transactions/`,
    (req, res, ctx) => {
      const result: TransactionType = transactions[0];

      if (req.body.wallet_from === 3) {
        // const errorResponse = { error: 'Unknown error happened!' };

        return res(ctx.status(400), ctx.json(generalError), ctx.delay(500));
      }

      return res(ctx.status(201), ctx.json(result), ctx.delay(3000));
    },
  ),

  rest.post<DefaultRequestBodyType & { [wallet_from: string]: number }>(
    `${API_URL}/transaction-proposals/`,
    (req, res, ctx) => {
      const result: TransactionProposalType = transactionProposal;

      if (req.body.wallet_from === 3) {
        const errorResponse = { error: 'Unknown error happened!' };

        return res(ctx.status(400), ctx.json(errorResponse), ctx.delay(500));
      }

      return res(ctx.status(201), ctx.json(result), ctx.delay(3000));
    },
  ),

  rest.patch<DefaultRequestBodyType & { [hint: string]: number }>(
    `${API_URL}/accounts/:accountId`,
    (req, res, ctx) => {
      const { accountId } = req.params;
      const { hint } = req.body;

      const foundAccount = accounts.find(
        (item) => item.id === Number(accountId),
      );

      if (foundAccount) {
        foundAccount.hint = hint;
      }

      const result = foundAccount;

      if (Number(accountId) === 3 || !foundAccount) {
        const errorResponse = { error: 'Unknown error happened!' };

        return res(ctx.status(400), ctx.json(errorResponse), ctx.delay(500));
      }

      return res(ctx.status(201), ctx.json(result), ctx.delay(3000));
    },
  ),
];
