import { useContext, useEffect, useState } from 'react';

import * as Form from 'shared/components/Form';
import * as Yup from 'yup';
import * as S from '../styled';
import AppContext from 'shared/contexts/AppContext';
import { useCreateGroupMutation } from '../hooks/useCreateGroupMutation';
import { useFormik } from 'formik';
import DropdownSelect from 'shared/components/DropdownSelect';
import { GroupFormValues } from '../types';
import { useFilters } from 'shared/hooks/useFilters';
import { ProtectedArea } from 'shared/components';
import { parseError } from 'shared/helpers/errors';
import { capitalize } from 'shared/helpers/format';

const initialFormValues: GroupFormValues = {
  name: '',
  participants: 1,
  signers: 1,
};

export const CreateGroupModal = () => {
  const { openModal } = useContext(AppContext);
  const [maxsigners, setMaxsigners] = useState(1);

  const { mutate: createGroup, error } = useCreateGroupMutation();

  const onSubmit = async ({ name, participants, signers }: any) => {
    createGroup({
      name,
      users: participants.map(({ email }: any) => email),
      signers_num: signers,
    });
  };

  const onCloseModal = () => {
    openModal(null);
  };

  useEffect(() => {
    localStorage.setItem('users_search', '{}');
    localStorage.setItem('create-dropdown', 'false');

    return () => {
      localStorage.setItem('users_search', '{}');
      localStorage.setItem('create-dropdown', 'false');
    };
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    participants: Yup.array().min(3).required('Required'),
    signers: Yup.number().min(2).max(maxsigners).required('Required'),
  });

  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <S.CreateModalForm onSubmit={formik.handleSubmit}>
      <Form.Fieldset>
        <Form.LabelWrap>
          <Form.Label>Group name</Form.Label>
        </Form.LabelWrap>
        <Form.StyledInput
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type={'text'}
          autoComplete={'off'}
          placeholder={'Group name'}
        />
        {formik.touched.name && Boolean(formik.errors.name) ? (
          <S.Error>{capitalize(String(formik.errors.name))}</S.Error>
        ) : null}
      </Form.Fieldset>
      <Form.Fieldset>
        <Form.LabelWrap>
          <Form.Label>Participants</Form.Label>
        </Form.LabelWrap>
        <Form.StyledInput
          name="participants"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type={'hidden'}
        />
        <DropdownSelect formik={formik} setMaxsigners={setMaxsigners} />
        {formik.touched.participants && Boolean(formik.errors.participants) ? (
          <S.Error>{capitalize(String(formik.errors.participants))}</S.Error>
        ) : null}
      </Form.Fieldset>
      <Form.Fieldset>
        <Form.LabelWrap>
          <Form.Label>Signers</Form.Label>
        </Form.LabelWrap>
        <Form.StyledInput
          name="signers"
          value={formik.values.signers}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="number"
          step={1}
          min={2}
          max={maxsigners - 1 >= 2 ? maxsigners - 1 : 2}
          placeholder="Number of signers"
        />
        {formik.touched.signers && Boolean(formik.errors.signers) ? (
          <S.Error>{capitalize(String(formik.errors.signers))}</S.Error>
        ) : null}
        {error ? <S.Error>{parseError(error)}</S.Error> : null}
      </Form.Fieldset>
      <S.Buttons>
        <S.SecondaryButton type="button" onClick={onCloseModal}>
          Cancel
        </S.SecondaryButton>
        <ProtectedArea every={['mpc.add_mpcgroup']}>
          <S.Button type="submit">Create group</S.Button>
        </ProtectedArea>
      </S.Buttons>
    </S.CreateModalForm>
  );
};
