import { fromTokenUnit, _getChain } from 'features/Multisig/helpers';
import { CurrencyType } from '../types';

export const currencies: Record<
  CurrencyType,
  { name: CurrencyType; logoUrl: string; precision?: number }
> = {
  BTC: { name: 'BTC', logoUrl: '/i/coins/btc.svg' /* precision: 5 */ },
  ETH: { name: 'ETH', logoUrl: '/i/coins/eth.svg' /* precision: 4 */ },
  GOR: { name: 'GOR', logoUrl: '/i/coins/eth.svg' /* precision: 4 */ },
  NATIVE_COIN: {
    name: 'NATIVE_COIN',
    logoUrl: '/i/coins/eth.svg' /* precision: 4 */,
  },
  EUR: { name: 'EUR', logoUrl: '/i/coins/eur.svg' /* precision: 2 */ },
  EURS: { name: 'EURS', logoUrl: '/i/coins/eurs.svg' /* precision: 2 */ },
  USD: { name: 'USD', logoUrl: '/i/coins/usd.svg' /* precision: 2 */ },
  USDT: { name: 'USDT', logoUrl: '/i/coins/usdt.svg' /* precision: 2 */ },
  BUSD: { name: 'BUSD', logoUrl: '/i/coins/busd.svg' /* precision: 2 */ },
  AAVE: { name: 'AAVE', logoUrl: '/i/coins/aave.svg' /* precision: 2 */ },
  ADA: { name: 'ADA', logoUrl: '/i/coins/ada.svg' /* precision: 2 */ },
  ATOM: { name: 'ATOM', logoUrl: '/i/coins/atom.svg' /* precision: 2 */ },
  AVAX: { name: 'AVAX', logoUrl: '/i/coins/avax.svg' /* precision: 2 */ },
  AXS: { name: 'AXS', logoUrl: '/i/coins/axs.svg' /* precision: 2 */ },
  BAT: { name: 'BAT', logoUrl: '/i/coins/bat.svg' /* precision: 2 */ },
  COMP: { name: 'COMP', logoUrl: '/i/coins/comp.svg' /* precision: 2 */ },
  CRV: { name: 'CRV', logoUrl: '/i/coins/crv.svg' /* precision: 2 */ },
  CVX: { name: 'CVX', logoUrl: '/i/coins/cvx.svg' /* precision: 2 */ },
  DAI: { name: 'DAI', logoUrl: '/i/coins/dai.svg' /* precision: 2 */ },
  DCR: { name: 'DCR', logoUrl: '/i/coins/dcr.svg' /* precision: 2 */ },
  DOGE: { name: 'DOGE', logoUrl: '/i/coins/doge.svg' /* precision: 2 */ },
  DYDX: { name: 'DYDX', logoUrl: '/i/coins/dydx.svg' /* precision: 2 */ },
  FET: { name: 'FET', logoUrl: '/i/coins/fet.svg' /* precision: 2 */ },
  FIL: { name: 'FIL', logoUrl: '/i/coins/fil.svg' /* precision: 2 */ },
  FTM: { name: 'FTM', logoUrl: '/i/coins/ftm.svg' /* precision: 2 */ },
  FTT: { name: 'FTT', logoUrl: '/i/coins/ftt.svg' /* precision: 2 */ },
  GRT: { name: 'GRT', logoUrl: '/i/coins/grt.svg' /* precision: 2 */ },
  ICP: { name: 'ICP', logoUrl: '/i/coins/icp.svg' /* precision: 2 */ },
  LEO: { name: 'LEO', logoUrl: '/i/coins/leo.svg' /* precision: 2 */ },
  LINK: { name: 'LINK', logoUrl: '/i/coins/link.svg' /* precision: 2 */ },
  LTC: { name: 'LTC', logoUrl: '/i/coins/ltc.svg' /* precision: 2 */ },
  MANA: { name: 'MANA', logoUrl: '/i/coins/mana.svg' /* precision: 2 */ },
  MATIC: { name: 'MATIC', logoUrl: '/i/coins/matic.svg' /* precision: 2 */ },
  MKR: { name: 'MKR', logoUrl: '/i/coins/mkr.svg' /* precision: 2 */ },
  OMG: { name: 'OMG', logoUrl: '/i/coins/omg.svg' /* precision: 2 */ },
  ONT: { name: 'ONT', logoUrl: '/i/coins/ont.svg' /* precision: 2 */ },
  QTUM: { name: 'QTUM', logoUrl: '/i/coins/qtum.svg' /* precision: 2 */ },
  REN: { name: 'REN', logoUrl: '/i/coins/ren.svg' /* precision: 2 */ },
  REP: { name: 'REP', logoUrl: '/i/coins/rep.svg' /* precision: 2 */ },
  SAND: { name: 'SAND', logoUrl: '/i/coins/sand.svg' /* precision: 2 */ },
  SHIB: { name: 'SHIB', logoUrl: '/i/coins/shib.svg' /* precision: 2 */ },
  SNX: { name: 'SNX', logoUrl: '/i/coins/snx.svg' /* precision: 2 */ },
  STORJ: { name: 'STORJ', logoUrl: '/i/coins/storj.svg' /* precision: 2 */ },
  SUSHI: { name: 'SUSHI', logoUrl: '/i/coins/sushi.svg' /* precision: 2 */ },
  UNI: { name: 'UNI', logoUrl: '/i/coins/uni.svg' /* precision: 2 */ },
  USDC: { name: 'USDC', logoUrl: '/i/coins/usdc.svg' /* precision: 2 */ },
  UST: { name: 'UST', logoUrl: '/i/coins/ust.png' /* precision: 2 */ },
  XTZ: { name: 'XTZ', logoUrl: '/i/coins/xtz.svg' /* precision: 2 */ },
  ZRX: { name: 'ZRX', logoUrl: '/i/coins/zrx.svg' /* precision: 2 */ },
  IOTA: { name: 'IOTA', logoUrl: '/i/coins/miota.svg' /* precision: 2 */ },
  DASH: { name: 'DASH', logoUrl: '/i/coins/dash.svg' /* precision: 2 */ },
  DOT: { name: 'DOT', logoUrl: '/i/coins/dot.svg' /* precision: 2 */ },
  SOL: { name: 'SOL', logoUrl: '/i/coins/sol.svg' /* precision: 2 */ },
  XMR: { name: 'XMR', logoUrl: '/i/coins/xmr.svg' /* precision: 2 */ },
  NEO: { name: 'NEO', logoUrl: '/i/coins/neo.svg' /* precision: 2 */ },
  ETC: { name: 'ETC', logoUrl: '/i/coins/etc.svg' /* precision: 2 */ },
  ZEC: { name: 'ZEC', logoUrl: '/i/coins/zec.svg' /* precision: 2 */ },
  EGLD: { name: 'EGLD', logoUrl: '/i/coins/egld.svg' /* precision: 2 */ },
  AMP: { name: 'AMP', logoUrl: '/i/coins/amp.svg' /* precision: 2 */ },
  FLOW: { name: 'FLOW', logoUrl: '/i/coins/flow.svg' /* precision: 2 */ },
  GMX: { name: 'GMX', logoUrl: '/i/coins/gmx.svg' /* precision: 2 */ },
  HNT: { name: 'HNT', logoUrl: '/i/coins/hnt.svg' /* precision: 2 */ },
  IMX: { name: 'IMX', logoUrl: '/i/coins/imx.svg' /* precision: 2 */ },
  OP: { name: 'OP', logoUrl: '/i/coins/op.svg' /* precision: 2 */ },
  SKL: { name: 'SKL', logoUrl: '/i/coins/skl.svg' /* precision: 2 */ },
  UMA: { name: 'UMA', logoUrl: '/i/coins/uma.svg' /* precision: 2 */ },
};

export function getCurrencyLogo(currency: CurrencyType): string {
  return currencies[currency]?.logoUrl;
}

export function getCurrencyFloat({
  value = '0',
  precision = 0,
}: {
  value: string;
  precision?: number;
}): number {
  const parts = value.split('.');
  const intPart = parts[0];
  const digitPart = parts[1] || '';
  const digitPartTruncated = digitPart.substr(0, precision || digitPart.length);

  return parseFloat(`${intPart}.${digitPartTruncated}`);
}

export function getCurrencyValue({
  currency,
  value,
  precision,
  minprecision,
}: {
  currency: CurrencyType | undefined;
  value: string;
  precision?: number;
  minprecision?: number;
}): string {
  if (!currency) {
    return '';
  }

  const floatValue = getCurrencyFloat({ value, precision });

  if (Number.isNaN(floatValue)) {
    return '';
  }

  return floatValue.toLocaleString('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: minprecision,
  });
}

export const getCryptoValue = (value: number) =>
  fromTokenUnit(value, _getChain().nativeCurrency.decimals);
