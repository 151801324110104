import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import {
  checkAreBothFutures,
  checkIsCrossWallet,
  checkIsDepositAddressWhitelisted,
  checkIsFromMultisig,
  checkIsFromWallet,
  checkIsInternal,
  checkIsShowFees,
  getMinWithdrawLimit,
  getWithdrawFee,
} from 'features/Transfer/functions';
import {
  DEFAULT_AMOUNT,
  DEFAULT_FROM,
  DEFAULT_TO,
  GET_DEFAULT_FILTERS,
  PATTERNS,
  SMALL_BALANCE_VALUE,
} from '../constants';
import AppContext from 'shared/contexts/AppContext';
import {
  ChangeTransferParticipantParams,
  TransferFiltersType,
  TransferParticipant,
} from '../types';
import {
  TransferExchangeFilterType,
  CurrencyType,
  ExchangeAccountsType,
  ExchangeApiIdType,
  WalletType,
} from 'shared/types';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { isUndefined } from 'shared/helpers/strings';
import {
  aboutToExceedLimit,
  aboutToExceedSingleLimit,
  canExecuteTransaction,
} from 'shared/helpers/transaction';
import { tfaRequired } from 'shared/components/OtpModal/UpdateOtpModal';
import { requestPost, requestGet } from 'shared/axios';
import { parseError } from 'shared/helpers/errors';

import { AccountsStoreInstance } from 'services';

interface TransferContextProps {
  amount: number;
  amountDisplay: string;
  filters: any;
  setFilters: any;
  changeAmount: any;
  from?: any;
  to?: any;
  error?: any;
  changeFrom: any;
  changeTo: any;
  resetFrom: any;
  resetTo: any;
  submitTransaction: any;
  handleIsConfirming: any;
  changeComment: any;
  changeCurrency: any;

  handleToggleCurrency: any;
  isCurrencyDropdownOpen: boolean;
  isSubmitting: boolean;
  comment: string;
  isValueMinExceed: boolean;
  bothSelected: boolean;
  toWalletHasDepositAddress: boolean;
  isFromWallet?: boolean;
  isFromMultisig?: boolean;
  isCrossWallet: boolean;
  bothAreFutures: boolean;
  depositAddressIsWhitelisted?: boolean;
  withdrawFee?: string | null;
  minWithdrawLimit?: string | null;
  isInternal: boolean;
  showFees: boolean;
  isChosenCurrencyAvailable?: boolean;
  isConfirmAvailable?: boolean;
  chosenCurrency?: Undefinedable<CurrencyType>;
  aboutToExecute: boolean;
  isTfaRequired: boolean;
  selectAllCurrencies: any;
  couldAddTransaction: boolean;
  couldAddTransactionProposal: boolean;
  exchangesFrom: ExchangeAccountsType[];
  exchangesTo: ExchangeAccountsType[];
  fromTypes: TransferExchangeFilterType;
  toTypes: TransferExchangeFilterType;
  selectAccountType: any;
  fromAccounts: any;
  toAccounts: any;
  fromAccountsIds: any;
  toAccountsIds: any;
  availableCurrencies: string[];

  useCheckAddressQuery: any;
  useGenerateAddressQuery: any;
  useUpdateAddressQuery: any;
  useGetDepositAddressQuery: any;
  setError: any;
  exchangesInfo: any;

  useGetWalletsFrom: any;
  useGetWalletsTo: any;

  filtersDebounceLoading: string | null;
  setFiltersDebounceLoading: any;

  handleFilterSearch: any;
  filterSearchValue: any;
}

const defaultContext: TransferContextProps = {
  aboutToExecute: false,
  amount: DEFAULT_AMOUNT,
  amountDisplay: String(DEFAULT_AMOUNT),
  fromTypes: {
    exchange_type: null,
    name: 'ALL',
  },
  toTypes: {
    exchange_type: null,
    name: 'ALL',
  },
  selectAccountType: () => {},
  filters: {},
  exchangesFrom: [],
  setError: () => {},
  exchangesTo: [],
  setFilters: () => {},
  changeAmount: () => {},
  changeFrom: () => {},
  changeTo: () => {},
  changeComment: () => {},
  changeCurrency: () => {},
  resetFrom: () => {},
  resetTo: () => {},
  submitTransaction: () => {},
  handleIsConfirming: () => {},
  isConfirmAvailable: false,
  isValueMinExceed: false,
  bothSelected: false,
  toWalletHasDepositAddress: false,
  isCrossWallet: false,
  bothAreFutures: false,
  isInternal: false,
  showFees: false,
  isChosenCurrencyAvailable: false,
  isSubmitting: false,
  isTfaRequired: false,
  isCurrencyDropdownOpen: false,
  handleToggleCurrency: () => {},
  selectAllCurrencies: () => {},
  fromAccounts: [],
  toAccounts: [],
  fromAccountsIds: [],
  toAccountsIds: [],
  couldAddTransaction: false,
  couldAddTransactionProposal: false,
  comment: '',
  availableCurrencies: [],
  useCheckAddressQuery: () => {},
  useGenerateAddressQuery: () => {},
  useGetDepositAddressQuery: () => {},
  useUpdateAddressQuery: () => {},
  exchangesInfo: [],
  useGetWalletsFrom: () => {},
  useGetWalletsTo: () => {},
  filtersDebounceLoading: null,
  setFiltersDebounceLoading: () => {},
  handleFilterSearch: () => {},
  filterSearchValue: null,
};

export const TransferContext =
  createContext<TransferContextProps>(defaultContext);

export const TransferProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    getAccountById,
    getAccountWallet,
    appSettings,
    openModal,
    setIsConfirming,
    rates,
    user,
    setTransactionStatus,
    exchanges,
  } = useContext(AppContext);
  const [isValueMinExceed, setIsValueMinExceed] = useState(false);
  const [isStatusKnown, setIsStatusKnown] = useState(false);
  const DEFAULT_FILTERS = GET_DEFAULT_FILTERS(appSettings);
  const [isCurrencyDropdownOpen, setIsCurrencyDropdownOpen] = useState(false);
  const [from, setFrom] = useState<Undefinedable<TransferParticipant>>();
  const [to, setTo] = useState<Undefinedable<TransferParticipant>>();
  const [amount, setAmount] = useState(DEFAULT_AMOUNT);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [comment, setComment] = useState('');
  const [chosenCurrency, setChosenCurrency] = useState<CurrencyType>();
  const [sourceColumn, setSourceColumn] = useState<null | 'from' | 'to'>(null);
  const [amountDisplay, setAmountDisplay] = useState(String(DEFAULT_AMOUNT));
  const [fromAccounts, setAccountsFrom] = useState<any>([]);
  const [toAccounts, setAccountsTo] = useState<any>([]);
  const [error, setError] = useState();
  const [filtersDebounceLoading, setFiltersDebounceLoading] = useState<
    null | 'from' | 'to' | 'all'
  >(null);
  const [filters, setFilters] = useLocalStorage(
    'transfer_filters',
    DEFAULT_FILTERS,
  );

  const navigate = useNavigate();

  const changeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e?.target?.value);
  };

  const { data: exchangesInfo } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['exchanges_info'],
    queryFn: async () => {
      const response = await requestGet('/exchanges/info/');
      return response.data;
    },
  });

  const useGetWalletsFrom = () => {
    return useQuery({
      queryKey: ['walletsFrom'],
      queryFn: async () => {
        const { data } = await requestGet('/wallets/', {
          params: {
            currency: filters.currencies.join(','),
            available_min: filters.isHideSmallBalances
              ? SMALL_BALANCE_VALUE
              : null,
            exchange_type: fromTypes.exchange_type,
            search: filterSearchValue.from,
          },
        });
        const res = data.length > 0 ? mapAccountsFromWallets(data) : [];
        setAccountsFrom(res);
        setFiltersDebounceLoading(null);

        return res;
      },
      enabled: false,
    });
  };

  const useGetWalletsTo = (chosenCurrency?: CurrencyType) => {
    return useQuery({
      queryKey: ['walletsTo'],
      queryFn: async () => {
        const { data } = await requestGet('/wallets/', {
          params: {
            currency: chosenCurrency || filters.currencies.join(','),
            exchange_type: toTypes.exchange_type,
            search: filterSearchValue.to,
          },
        });

        const res = data.length > 0 ? mapAccountsFromWallets(data) : [];
        setAccountsTo(res);
        setFiltersDebounceLoading(null);

        return res;
      },
      enabled: false,
    });
  };

  const { refetch: fetchWalletsTo } = useGetWalletsTo();

  const changeFrom = ({
    exchangeId,
    accountId,
    walletId,
    currencyId,
  }: ChangeTransferParticipantParams) => {
    resetTo();
    // @ts-ignore
    if (walletId !== DEFAULT_FROM.wallet_id && from?.wallet.id === walletId) {
      resetTo();
      resetFrom();
      fetchWalletsTo();
      return;
    }

    const account = getAccountById(accountId);
    if (!account) return;
    const wallet = getAccountWallet(accountId, { walletId, currencyId });
    if (!wallet) return;

    setFrom({ account, wallet });
    setChosenCurrency(currencyId);

    const currentPattern = PATTERNS[exchangeId] || PATTERNS.default;
    const decimalPlaces = currentPattern.digitsLength;
    const roundedValue = Number().toFixed(decimalPlaces);
    const formattedAmountDisplay =
      Number(roundedValue) === 0 ? '0' : roundedValue;

    const trimmedAmountDisplay = formattedAmountDisplay
      .replace(/(\.\d*[1-9])0+$/, '$1')
      .replace(/\.$/, '');

    setAmountDisplay(String(trimmedAmountDisplay));

    if (accountId === DEFAULT_FROM.account_id) {
      if (sourceColumn === 'from') {
        if (to?.account?.id === DEFAULT_TO.account_id) {
          setSourceColumn(null);
        } else {
          setSourceColumn('to');
        }
      }
    } else {
      if (!sourceColumn) {
        setSourceColumn('from');
      } else {
        if (
          (sourceColumn === 'from' && currencyId !== chosenCurrency) ||
          accountId === to?.account?.id
        ) {
          const account = getAccountById(DEFAULT_TO.account_id);
          if (!account) return;
          const wallet = getAccountWallet(DEFAULT_TO.account_id, {
            walletId: DEFAULT_TO.wallet_id,
          });
          if (!wallet) return;
          setTo({
            account,
            wallet,
          });

          if (accountId === to?.account?.id) {
            setSourceColumn('from');
          }
        }
      }
    }
  };
  function changeCurrency(currency: CurrencyType | '') {
    resetFrom();
    resetTo();
    setSourceColumn(null);
    setFiltersDebounceLoading('all');

    if (currency) {
      const isCurrentlyActive = filters.currencies.includes(currency);
      const allSecondaryToBeActive = appSettings.currencies_secondary.every(
        (cur) => {
          return (
            (currency !== cur && filters.currencies.includes(cur)) ||
            (currency === cur && !isCurrentlyActive)
          );
        },
      );

      setFilters((filters: TransferFiltersType) => ({
        ...filters,
        currencies: isCurrentlyActive
          ? filters.currencies.filter((item) => item !== currency)
          : [...filters.currencies, currency],
        isSelectAll: allSecondaryToBeActive,
      }));
    }

    setChosenCurrency(undefined);
  }

  const changeTo = ({
    accountId,
    walletId,
    currencyId,
  }: ChangeTransferParticipantParams) => {
    if (walletId !== DEFAULT_TO.wallet_id && to?.wallet?.id === walletId) {
      resetTo();
      return;
    }

    const account = getAccountById(accountId);
    if (!account) return;
    const wallet = getAccountWallet(accountId, { walletId, currencyId });
    if (!wallet) return;

    setTo({ account, wallet });
    setChosenCurrency(currencyId);

    const currentAmount =
      minWithdrawLimit && !isInternal
        ? String(parseFloat(minWithdrawLimit))
        : String(DEFAULT_AMOUNT);
    setAmountDisplay(currentAmount);
    setAmount(Number(currentAmount));

    if (accountId === DEFAULT_TO.account_id) {
      if (sourceColumn === 'to') {
        if (from?.account?.id === DEFAULT_FROM.account_id) {
          setSourceColumn(null);
        } else {
          setSourceColumn('from');
        }
      }
    } else {
      if (!sourceColumn) {
        setSourceColumn('to');
      } else {
        if (sourceColumn === 'to' && currencyId !== chosenCurrency) {
          const account = getAccountById(DEFAULT_FROM.account_id);
          if (!account) return;
          const wallet = getAccountWallet(DEFAULT_FROM.account_id, {
            walletId: DEFAULT_FROM.wallet_id,
          });
          if (!wallet) return;
          setTo({
            account,
            wallet,
          });
        }
      }
    }
  };
  const handleToggleCurrency = (forcedValue?: boolean) => {
    setIsCurrencyDropdownOpen((isCurrencyDropdownOpen) =>
      forcedValue !== undefined ? forcedValue : !isCurrencyDropdownOpen,
    );
  };
  const resetFrom = () => {
    changeFrom({
      exchangeId: DEFAULT_FROM.exchange_api_id as ExchangeApiIdType,
      accountId: DEFAULT_FROM.account_id,
      walletId: DEFAULT_FROM.wallet_id,
      currencyId: undefined,
    });
    setFrom(undefined);
  };

  const resetTo = () => {
    changeTo({
      exchangeId: DEFAULT_TO.exchange_api_id as ExchangeApiIdType,
      accountId: DEFAULT_TO.account_id,
      walletId: DEFAULT_TO.wallet_id,
      currencyId:
        from?.account?.id !== DEFAULT_FROM.account_id
          ? (chosenCurrency as CurrencyType)
          : undefined,
    });
    setTo(undefined);
  };
  const submitTransaction = async ({
    fromWallet,
    toWallet,
    network,
    depositAddress,
    comment,
    tfa,
    otp,
  }: {
    fromWallet: WalletType | null;
    toWallet: WalletType | null;
    network: string;
    depositAddress: string;
    amountDisplay: string;
    comment: string;
    tfa?: string;
    otp?: string;
  }) => {
    setIsSubmitting(true);
    setError(undefined);

    const url = '/transactions/';

    const body = {
      wallet_from: fromWallet?.id,
      network,
      wallet_to: toWallet?.id,
      amount: amountDisplay,
      description: comment,
      ...(depositAddress && { deposit_address: depositAddress }),
      ...(couldAddTransaction ? tfa && { tfa } : {}),
      ...(Boolean(network) && network !== '' && { network }),
    };

    try {
      const response = await requestPost(url, body, {
        headers: {
          ...(otp && { 'X-AUTH-HOTP': otp }),
        },
      });
      const data = response.data;

      const status = response?.status !== 201 ? 'error' : 'ok';

      if (data) {
        if (
          status === 'ok' &&
          process.env.REACT_APP_REDIRECT_AFTER_TX_CREATION
        ) {
          navigate('/transactions');
          openModal(null);
        } else {
          const creationStatus = {
            ...data,
            api_creation_status: status,
          };

          setTransactionStatus(creationStatus);
          setIsStatusKnown(true);
        }

        openModal(null);
        setIsConfirming(false);
        setError(undefined);
      }
    } catch (err) {
      // @ts-ignore
      setError(parseError(err, { fromWallet, toWallet }));
      setIsSubmitting(false);

      // @ts-ignore
      throw err;
    }
    setIsSubmitting(false);
  };

  const minWithdrawLimit = getMinWithdrawLimit(from);
  const debounceAmount = useCallback(
    _.debounce((value) => {
      return setIsValueMinExceed(
        !!value.length && Number(value) < Number(minWithdrawLimit),
      );
    }, 1000),
    [minWithdrawLimit],
  );

  const changeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const defaultPattern = PATTERNS.default.pattern;
    const currentPattern =
      PATTERNS[from?.account?.exchange as ExchangeApiIdType]?.pattern ||
      defaultPattern;
    if (currentPattern?.test(value)) {
      let newAmount = Number(value) || 0;
      newAmount = newAmount >= 0 ? newAmount : 0;

      setAmountDisplay(value);
      setAmount(newAmount);
      setIsValueMinExceed(false);
      debounceAmount(value);
    }
  };
  const getCurrencies = (includeSecondary: boolean) => {
    if (includeSecondary) {
      return [...filters.currencies, ...appSettings.currencies_secondary];
    } else {
      return filters.currencies.filter(
        (cur: any) => !appSettings.currencies_secondary.includes(cur),
      );
    }
  };

  const handleIsConfirming = (value: boolean) => {
    if (value) {
      setIsConfirming(true);
    } else {
      setIsConfirming(false);
    }
  };

  const selectAllCurrencies = (e: any) => {
    setFiltersDebounceLoading('all');
    resetFrom();
    resetTo();
    setSourceColumn(null);

    setFilters((filters: TransferFiltersType) => ({
      ...filters,
      currencies: getCurrencies(e.target.checked),
      isSelectAll: e.target.checked,
    }));

    setChosenCurrency(undefined);
  };

  const filterExchanges = useMemo(() => {
    return (selectedFilter: TransferExchangeFilterType) => {
      return exchanges.filter((data: ExchangeAccountsType) => {
        if (selectedFilter.name === 'ALL') {
          return true;
        } else if (data.exchange === selectedFilter.name) {
          return true;
        } else if (
          selectedFilter.name === 'EXCHANGES' &&
          data.exchange !== 'WALLETS'
        ) {
          return true;
        }
      });
    };
  }, [exchanges]);

  const [selectedAccountTypes, setSelectedAccountTypes] = useState({
    from: {
      exchange_type: null,
      name: 'ALL',
    },
    to: {
      exchange_type: null,
      name: 'ALL',
    },
  });

  const [filterSearchValue, setFilterSearchValue] = useState({
    from: '',
    to: '',
  });

  const selectAccountType = (
    side: 'from' | 'to',
    type: TransferExchangeFilterType[],
  ) => {
    setFiltersDebounceLoading(side);
    setSelectedAccountTypes((currentValue) => ({
      ...currentValue,
      [side]: type,
    }));
  };

  const handleFilterSearch = (side: 'from' | 'to', value: string) => {
    setFiltersDebounceLoading(side);
    setFilterSearchValue((currentValue) => ({
      ...currentValue,
      [side]: value,
    }));
  };

  const useGetDepositAddressQuery = (
    walletId: number,
    currency: CurrencyType,
    network: string,
    enabled: boolean,
  ) => {
    const id = `${walletId}_${currency}_${network}`;
    return useQuery({
      refetchOnMount: 'always',
      enabled,
      refetchOnWindowFocus: false,
      queryKey: [`getDepositAddress_${id}`],
      queryFn: async () => {
        const response = await requestGet('/deposit-addresses/', {
          params: {
            wallet: walletId,
            currency,
            network,
          },
        });
        return response?.data?.[0] || null;
      },
    });
  };

  const useUpdateAddressQuery = (id: number) => {
    return useQuery({
      queryKey: [`updateAddress_${id}`],
      refetchOnWindowFocus: false,
      enabled: false,
      queryFn: async () => {
        const response = await requestPost(`/deposit-addresses/${id}/update/`);
        return response.data;
      },
    });
  };

  const useGenerateAddressQuery = (id: number) => {
    return useQuery({
      queryKey: [`generateAddress_${id}`],
      refetchOnWindowFocus: false,
      enabled: false,
      queryFn: async () => {
        const response = await requestPost(
          `/deposit-addresses/${id}/generate/`,
        );
        return response.data;
      },
    });
  };

  const useCheckAddressQuery = (id: number) => {
    return useQuery({
      queryKey: [`checkAddress_${id}`],
      refetchOnWindowFocus: false,
      enabled: false,
      queryFn: async () => {
        const response = await requestGet(`/deposit-addresses/${id}/check/`);
        return response.data;
      },
    });
  };

  const nest = (
    seq: any[],
    keys: Array<string | ((obj: any) => string)>,
  ): any => {
    if (!keys.length) {
      return seq;
    }
    const [first, ...rest] = keys;
    return _.mapValues(_.groupBy(seq, first), (value) => nest(value, rest));
  };

  const mapAccountsFromWallets = (data: any) => {
    const { accountsMap } = AccountsStoreInstance;

    const mappedWallets = data?.map(({ account: accId, ...rest }: any) => {
      const account = accountsMap.get(Number(accId));
      if (account)
        return {
          exchange: account?.exchange || '',
          account: accId,
          ...rest,
        };
    });

    const grouped =
      mappedWallets.length > 0
        ? nest(mappedWallets, ['exchange', 'account'])
        : undefined;

    const res = Object.keys(grouped)
      .sort((a, b) => (a === 'WALLETS' ? -1 : b === 'WALLETS' ? 1 : 0))
      .map((exchange) => {
        return {
          exchange,
          accounts: Object.keys(grouped[exchange]).map((accId) => {
            const account = accountsMap.get(Number(accId));
            return {
              ...account,
              wallets: grouped[exchange][accId],
            };
          }),
        };
      });

    return res;
  };

  const toAvailableCurrencies: CurrencyType[] =
    to?.account?.wallets?.length && to?.account?.wallets?.length > 0
      ? to?.account?.wallets.map((item) => item.currency).flat()
      : [];
  const isChosenCurrencyAvailable = toAvailableCurrencies.includes(
    chosenCurrency as CurrencyType,
  );
  const aboutToExecute = canExecuteTransaction(
    user?.proposal_vote_weight,
    0,
    appSettings.proposal_votes_value_to_approve,
  );
  const exceedsLimit = aboutToExceedLimit(
    amount,
    from?.wallet?.currency,
    user,
    rates,
  );
  const exceedsSingleLimit = aboutToExceedSingleLimit(
    amount,
    from?.wallet?.currency,
    user,
    rates,
  );
  const couldAddTransaction =
    !isUndefined(user?.proposal_vote_weight) &&
    Number(user?.proposal_vote_weight) >=
      appSettings.proposal_votes_value_to_approve;

  const couldAddTransactionProposal =
    !isUndefined(user?.proposal_vote_weight) &&
    Number(user?.proposal_vote_weight) <
      appSettings.proposal_votes_value_to_approve;
  const bothSelected = Boolean(from && to);
  // @ts-ignore
  const toWalletHasDepositAddress = to?.wallet?.deposit_addresses?.length > 0;
  const isFromWallet = checkIsFromWallet(from);
  const isFromMultisig = checkIsFromMultisig(from);
  const isCrossWallet = checkIsCrossWallet(from, to);
  const bothAreFutures = checkAreBothFutures(from, to);
  const depositAddressIsWhitelisted = checkIsDepositAddressWhitelisted(
    // @ts-ignore
    to?.wallet,
  );
  const withdrawFee = getWithdrawFee(from);
  const isInternal = checkIsInternal(from, to);
  const showFees = checkIsShowFees(
    isInternal,
    isFromWallet,
    minWithdrawLimit,
    withdrawFee,
    depositAddressIsWhitelisted,
  );
  const isTfaRequired = tfaRequired.includes(
    from?.account?.exchange as ExchangeApiIdType,
  );
  const isMinLimitExceed =
    minWithdrawLimit && !isInternal ? Number(minWithdrawLimit) <= amount : true;
  const isConfirmAvailable =
    (couldAddTransaction || couldAddTransactionProposal) &&
    from?.wallet &&
    to?.wallet &&
    (isCrossWallet || toWalletHasDepositAddress) &&
    amount > 0 &&
    isChosenCurrencyAvailable &&
    (Number(from?.wallet?.available) || 0) >= amount &&
    isMinLimitExceed &&
    !exceedsLimit &&
    !bothAreFutures &&
    !exceedsSingleLimit;

  const { from: fromTypes, to: toTypes } = selectedAccountTypes;

  const exchangesFrom = filterExchanges(fromTypes);
  const exchangesTo = filterExchanges(toTypes);
  const fromAccountsIds =
    fromAccounts?.length > 0 &&
    fromAccounts?.flatMap((item: any) =>
      item.accounts.map(({ id }: { id: number }) => id),
    );
  const toAccountsIds =
    toAccounts?.length > 0 &&
    toAccounts?.flatMap((item: any) =>
      item.accounts.map(({ id }: { id: number }) => id),
    );
  const availableCurrencies = [
    ...appSettings.currencies_primary,
    ...appSettings.currencies_secondary,
  ];

  return (
    <TransferContext.Provider
      value={{
        from,
        to,
        amount,
        changeAmount,
        changeCurrency,
        error,
        filters,
        setError,
        setFilters,
        changeFrom,
        changeTo,
        resetTo,
        resetFrom,
        submitTransaction,
        handleIsConfirming,
        comment,
        changeComment,
        isSubmitting,
        isTfaRequired,
        fromTypes,
        toTypes,
        selectAccountType,
        exchangesFrom,
        exchangesTo,
        aboutToExecute,
        isChosenCurrencyAvailable,
        isConfirmAvailable,
        isValueMinExceed,
        amountDisplay,
        chosenCurrency,
        bothSelected,
        toWalletHasDepositAddress,
        isFromWallet,
        isFromMultisig,
        isCrossWallet,
        bothAreFutures,
        depositAddressIsWhitelisted,
        withdrawFee,
        minWithdrawLimit,
        isInternal,
        showFees,
        handleToggleCurrency,
        isCurrencyDropdownOpen,
        couldAddTransaction,
        couldAddTransactionProposal,
        selectAllCurrencies,
        fromAccounts,
        toAccounts,
        fromAccountsIds,
        toAccountsIds,
        availableCurrencies,
        useCheckAddressQuery,
        useGenerateAddressQuery,
        useGetDepositAddressQuery,
        useUpdateAddressQuery,
        exchangesInfo,

        useGetWalletsFrom,
        useGetWalletsTo,

        filtersDebounceLoading,
        setFiltersDebounceLoading,

        handleFilterSearch,
        filterSearchValue,
      }}
    >
      {children}
    </TransferContext.Provider>
  );
};

export const useTransfer = () => useContext(TransferContext);
