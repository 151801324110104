import { AccountType, CurrencyType } from 'shared/types';
import { IconArrowWrap, IconArrow } from '../../components/ExchangeAbstract';
import { AccountAliases } from './AccountAliases';
import { AccountBalanceStatus } from './AccountBalanceStatus';
import { AccountExchangeIcon } from './AccountExchangeIcon';
import { AccountNameLabel } from './AccountNameLabel';
import { AccountTags } from './AccountTags';
import { AccountViolations } from './AccountViolations';
import { useMemo } from 'react';

import { getViolatedCurrencies } from 'features/Transfer/functions';
import * as S from './AccountStyled';
import { AccountHints } from './AccountHints';

interface AccountHeaderProps {
  account: AccountType;
  subAccounts: AccountType[];
  opened: boolean;
  toggle: any;
}

export const AccountHeader = ({
  account,
  opened,
  toggle,
  subAccounts,
}: AccountHeaderProps) => {
  const wallet = account?.wallets?.find(
    ({ deposit_address }) => !!deposit_address,
  );

  const violatedCurrencies = useMemo(() => {
    return getViolatedCurrencies(account);
  }, [account]);
  const isViolated = violatedCurrencies?.length > 0;

  return (
    <S.AccountHeader isViolated={isViolated}>
      <S.AccountHeaderTop onClick={toggle}>
        <IconArrowWrap rotateIcon={opened}>
          <IconArrow />
        </IconArrowWrap>
        <AccountExchangeIcon exchange={account?.exchange} />
        <S.AccountTitle>
          <S.AccountName>
            <AccountNameLabel name={account?.name} />
            <AccountAliases
              address={wallet?.deposit_address as string}
              aliases={account?.sgx_aliases}
            />
            {account.thresholds && (
              <AccountViolations
                violatedCurrencies={violatedCurrencies as CurrencyType[]}
                thresholds={account.thresholds}
              />
            )}
          </S.AccountName>
          <AccountBalanceStatus
            hasSubAccounts={subAccounts?.length > 0}
            isBalanceOutdated={account?.is_balance_outdated}
            balanceUpdatedAt={account?.balance_updated_at as string}
          />
        </S.AccountTitle>
      </S.AccountHeaderTop>
      <AccountTags tags={account?.tags} />
      <AccountHints account={account} />
    </S.AccountHeader>
  );
};
