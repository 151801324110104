import { FC } from 'react';
import Tippy from '@tippyjs/react';
import * as S from '../styled';
import { UserStoreInstance } from 'services';
import moment from 'moment';
import { addMinutes, format } from 'date-fns';

interface PaymentLimitCardProps {
  title: string;
}

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const PaymentLimitCard: FC<PaymentLimitCardProps> = ({ title }) => {
  const { user } = UserStoreInstance;

  if (!user) return null;

  const { period_limits, period_payments } = user;

  const type = period_limits.WEEK ? 'WEEK' : 'DAY';

  const limit = period_limits[type];
  const payments = period_payments[type];

  const today = new Date();

  const minutesDifference = today.getTimezoneOffset();
  const day = today.getDay();
  const lastDayDate = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate() + (type === 'WEEK' ? 6 - day : 0),
    24 - minutesDifference / 60,
  );

  const lastDayUtc = moment(addMinutes(lastDayDate, minutesDifference));

  const lastDay = format(lastDayDate, 'dd MMM, HH:mm').toUpperCase();

  const transition_width = (Number(payments) * 100) / Number(limit);

  const paymentsLeftValue = USDollar.format(Number(limit) - Number(payments));

  const paymentsLeft =
    Number(limit) < Number(payments) ? '$0' : paymentsLeftValue;

    const timeZone = -(minutesDifference / 60);

  return (
    <S.PaymentLimitCardWrapper>
      <S.CardHeader>
        <S.CardHeaderText>
          <S.IconLimit />
          {title}:&nbsp;
          <span data-id="limit-total">{USDollar.format(Number(limit))}</span>
        </S.CardHeaderText>
        <S.CardSetWrapper
          style={{
            color: limit ? 'var(--success)' : 'var(--object-secondary)',
          }}
        >
          <Tippy
            theme="transparent"
            placement="top"
            content={`${lastDayUtc.format('dddd, D MMMM, HH:mm')} GMT`}
          >
            <S.PaymentLimitDate>
              {limit ? <S.IconCheck /> : <S.IconUncheck />}
              {`${type === 'WEEK' ? 'WEEKLY' : 'DAILY'} / RESETS ${lastDay} (GMT+${timeZone})`}
            </S.PaymentLimitDate>
          </Tippy>
        </S.CardSetWrapper>
      </S.CardHeader>
      <S.PaymentLimitBarWrap>
        <S.PaymentLimitBar style={{ maxWidth: transition_width + '%' }}>
          <Tippy
            theme="transparent"
            placement="top"
            content={
              <div>
                <strong>
                  {((Number(payments) / Number(limit)) * 100).toFixed(2)} %
                </strong>
              </div>
            }
          >
            <S.PaymentLimitBarValue />
          </Tippy>
        </S.PaymentLimitBar>
      </S.PaymentLimitBarWrap>
      <S.CardFooter>
        <S.PaymentLimitLabel>
          <S.PaymentLimitLabelValue data-id="limit-spent">
            {USDollar.format(Number(payments))}
          </S.PaymentLimitLabelValue>
          Spent
        </S.PaymentLimitLabel>
        <S.PaymentLimitLabel style={{ color: 'var(--placeholder)' }}>
          <S.PaymentLimitLabelValue data-id="limit-left">
            {paymentsLeft}
          </S.PaymentLimitLabelValue>
          Left
        </S.PaymentLimitLabel>
      </S.CardFooter>
    </S.PaymentLimitCardWrapper>
  );
};
