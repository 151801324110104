import { MouseEventHandler, useContext, useEffect } from 'react';
import { Transaction } from 'services/Transactions/types';
import { notify } from 'shared/components/Notification/notify';
import {
  tfaRequired,
  UpdateOtpModal,
} from 'shared/components/OtpModal/UpdateOtpModal';
import AppContext from 'shared/contexts/AppContext';
import { parseError } from 'shared/helpers/errors';
import { canExecuteTransaction } from 'shared/helpers/transaction';
import { useChangeProposalStatus } from './useChangeProposalStatus';

interface UseVoteButtonsProps {
  tx: Transaction;
  toggleUpdating: any;
  toggleDetails: any;
  toggleHighlighting: any;
  setError: any;
}

export const useVoteButtons = ({
  tx,
  toggleUpdating,
  toggleDetails,
  toggleHighlighting,
  setError,
}: UseVoteButtonsProps) => {
  const { openModal, appSettings, user } = useContext(AppContext);
  const isTfaRequired = tfaRequired.includes(
    tx?.account_from?.exchange as string,
  );
  const aboutToExecute = canExecuteTransaction(
    user?.proposal_vote_weight,
    tx.sum_of_votes,
    appSettings.proposal_votes_value_to_approve,
  );

  const { mutate } = useChangeProposalStatus({
    setIsUpdating: toggleUpdating,
    setIsOpen: toggleDetails,
    setHighlighted: toggleHighlighting,
    proposal: tx,
  });

  const voteUp: MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();

    if (!appSettings.is_hotp_enabled && (!isTfaRequired || !aboutToExecute)) {
      mutate(
        {
          status: 'vote-up',
        },
        {
          onError: (error) => {
            const errorText = parseError(error);
            setError(errorText);
            openModal(null);
          },
        },
      );
    } else {
      openModal({
        title: 'Security Verification',
        component: () => (
          <UpdateOtpModal
            proposal={tx}
            status="vote-up"
            setIsUpdating={toggleUpdating}
            setIsOpen={toggleDetails}
            setHighlighted={toggleHighlighting}
            setError={setError}
          />
        ),
      });
    }
  };

  const voteDown: MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();

    if (!appSettings.is_hotp_enabled) {
      mutate(
        {
          status: 'vote-down',
        },
        {
          onError: (error) => {
            const errorText = parseError(error);
            setError(errorText);
            openModal(null);
          },
        },
      );
    } else {
      openModal({
        title: 'Security Verification',
        component: () => (
          <UpdateOtpModal
            proposal={tx}
            status="vote-down"
            setIsUpdating={toggleUpdating}
            setIsOpen={toggleDetails}
            setHighlighted={toggleHighlighting}
            setError={setError}
          />
        ),
      });
    }
  };

  return { voteUp, voteDown };
};
