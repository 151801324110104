import { useContext, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';

import { TransactionDetails } from './TransactionDetails';

import * as S from 'features/Transactions/view/styled';
import { AccountInfo } from '../shared/AccountInfo';
import { useToggle } from 'shared/hooks/useToggle';
import { isUndefined } from 'shared/helpers/strings';
import AppContext from 'shared/contexts/AppContext';
import { Transaction } from 'services/Transactions/types';
import { getTransactionDisplayStatus } from 'shared/helpers/transaction';
import { StatusType } from 'shared/types';
import { useVoteButtons } from '../hooks/useVoteButtons';
import { getCoinsInfoById } from 'shared/constants/coins';
import { formatAccount } from 'shared/helpers/format';
import { IconInfo } from '../Filters/styled';
import Tippy from '@tippyjs/react';

const walletNames = {
  'coinm-futures': 'CoinM futures',
  funding: 'Funding',
  margin: 'Margin',
  spot: 'Spot',
  'usdm-futures': 'UsdM futures',
  exchange: 'Exchange',
  wallet: 'Wallet',
};

export const getTxColValue = (
  txId: string | number | null,
  isInternal: boolean,
) => {
  if (isInternal) {
    return 'Transfer';
  } else if (txId) {
    return formatAccount(String(txId), true);
  } else {
    return '...';
  }
};

interface TransactionRowProps {
  tx: Transaction;
}

const icons = {
  ['Awaiting confirmations']: S.IconClock,
  ['Awaiting approval']: S.IconClock,
  Processing: S.IconProcessing,
  ['Approved by you']: S.IconApproved,
  ['Rejected by you']: S.IconRejected,
};

export const TransactionRow = ({ tx }: TransactionRowProps) => {
  const [isOpen, toggleDetails] = useToggle();
  const [isHighlighted, toggleHighlighting] = useToggle();
  const [_, toggleUpdating] = useToggle();
  const [voteError, setError] = useState('');
  const { appSettings, user, accounts } = useContext(AppContext);
  const { voteUp, voteDown } = useVoteButtons({
    tx,
    toggleDetails,
    toggleHighlighting,
    toggleUpdating,
    setError,
  });

  const {
    wallet_to,
    wallet_from,
    status,
    account_from,
    account_to,
    id,
    tx_id,
    votes,
    external_id,
    updated_at,
    sum_of_votes,
    created_by,
    amount,
    currency,
    is_internal_transfer: isInternal,
  } = tx;

  const userVote = useMemo(
    () => tx.votes.find(({ user: email }) => email === user?.email),
    [tx, user],
  );
  const lowerCaseStatus = useMemo(
    () => getTransactionDisplayStatus(status.toUpperCase(), userVote),
    [status, userVote],
  );
  const txColValue = useMemo(
    () => getTxColValue(tx_id ? tx_id : external_id, tx.is_internal_transfer),
    [status, userVote],
  );

  const [date, time] = useMemo(
    () => [
      !isUndefined(updated_at)
        ? format(parseISO(updated_at), 'yyyy-MM-dd')
        : '...',
      !isUndefined(updated_at)
        ? format(parseISO(updated_at), 'HH:mm:ss')
        : '...',
    ],
    [updated_at],
  );

  const logoUrl = useMemo(
    () => getCoinsInfoById(currency)?.logo_url || null,
    [currency, tx],
  );

  const toTitleCase = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  // @ts-ignore
  const Icon = icons[lowerCaseStatus];
  const isExternal = !created_by;
  const isUnvoted = created_by && sum_of_votes === null;

  const fromType = walletNames[wallet_from?.type as keyof typeof walletNames];
  const toType = walletNames[wallet_to?.type as keyof typeof walletNames];

  const getCorrectSumOfVotes = useMemo(() => {
    if (isExternal) {
      return sum_of_votes;
    } else if (status === StatusType.CLOSED) {
      return appSettings.proposal_votes_value_to_reject;
    } else if (status === StatusType.QUEUED) {
      return Math.min(
        Math.max(sum_of_votes, appSettings.proposal_votes_value_to_reject),
        appSettings.proposal_votes_value_to_approve
      );
    } else {
      return appSettings.proposal_votes_value_to_approve;
    }
  }, [status, sum_of_votes, appSettings]);

  return (
    <S.Row
      key={id}
      className={isHighlighted ? 'highlighted-border' : ''}
      data-id={id}
      isOpen={isOpen}
      isProcessing={status === 'processing'}
    >
      <S.TransactionIntro onClick={() => toggleDetails()}>
        <S.DateCol>
          <div>{date}</div>
          <S.GrayInlineText>{time}</S.GrayInlineText>
        </S.DateCol>
        <S.TxCol>{txColValue}</S.TxCol>
        <S.FromCol>
          <AccountInfo account={account_from} currentTypeString={fromType} />
        </S.FromCol>
        <S.ToCol>
          <AccountInfo account={account_to} currentTypeString={toType} />
        </S.ToCol>
        <S.AmountCol>
          <div>{Number(amount) || '-'}</div>
          <S.CurrencyInfo>
            <S.ToExchangeIcon
              style={{
                backgroundImage: `url(${logoUrl})`,
              }}
            />
            <S.GrayInlineText>{currency}</S.GrayInlineText>
          </S.CurrencyInfo>
        </S.AmountCol>
        <S.ConfirmationsCol>
          {!isExternal && !isUnvoted ? (
            <>
              <S.ConfirmationsIcon />
              {getCorrectSumOfVotes || 0} out of{' '}
              {appSettings.proposal_votes_value_to_approve}
            </>
          ) : isExternal ? (
            <>
              <S.ArrowIcon />
              External tx
            </>
          ) : (
            <>
              <S.ConfirmationsIcon />
              {sum_of_votes || 0} out of{' '}
              {appSettings.proposal_votes_value_to_approve}&nbsp;
              <Tippy
                theme="transparent"
                placement="auto"
                content="This is an old transaction that was completed before the implementation of the voting process"
              >
                <IconInfo />
              </Tippy>
            </>
          )}
        </S.ConfirmationsCol>
        <S.StatusCol>
          <S.Status status={status} localStatus={lowerCaseStatus}>
            {lowerCaseStatus}{' '}
            {Icon &&
              [
                StatusType.QUEUED,
                StatusType.INITIATED,
                StatusType.UNCONFIRMED,
                StatusType.PENDING,
                StatusType.SENT,
                StatusType.RECEIVED,
              ].includes(status) ? (
              <Icon />
            ) : null}
          </S.Status>
        </S.StatusCol>
      </S.TransactionIntro>

      {isOpen && (
        <TransactionDetails voteError={voteError} voteDown={voteDown} voteUp={voteUp} tx={tx} />
      )}
    </S.Row>
  );
};
