import * as S from 'features/Transactions/view/styled';
import { useContext } from 'react';
import {
  TransactionDisplayStatus,
  TransactionDisplayStatusType,
} from 'services/Transactions/types';
import AppContext from 'shared/contexts/AppContext';
import { isUndefined } from 'shared/helpers/strings';
import { StatusType } from 'shared/types';
import { getDropdownFilters } from '../../constants';
import {
  FilterStatus,
  FilterSearch,
  FilterDates,
  FilterDropdown,
  FilterRange,
} from '../Filters';
import { FilterViewOptions } from '../Filters/FilterViewOptions';

import { useFilters } from 'shared/hooks/useFilters';
import { useIsMobile } from 'shared/hooks/useIsMobile';
import { requestGet } from 'shared/axios';
import queryString from 'query-string';
import { parseDates } from 'shared/helpers/query';
import { useToggle } from 'shared/hooks/useToggle';
import { Loader } from 'shared/components';

interface TransactionsFiltersProps {
  minDate: Date | null;
  maxDate: Date | null;
  minAmount: number;
  maxAmount: number;
  onSubmit: () => void;
  disabled: boolean;
  isMobile: boolean;
  isAsideButtonChecked: string;
  setIsAsideButtonChecked: any;
  setIsMobile: any;
}

const storageKey = 'transactions_filters';

export const stringifyUrl = (url: string, filters: any) => {
  return queryString.stringifyUrl(
    {
      url,
      query: {
        ...filters,
      },
    },
    { arrayFormat: 'separator' },
  );
};

const mapStatus = (s: TransactionDisplayStatusType) => {
  if (s === TransactionDisplayStatus.AWAITING_CONFIRMATIONS) {
    return [StatusType.QUEUED];
  } else if (s === TransactionDisplayStatus.AWAITING_APPROVAL) {
    return [StatusType.UNCONFIRMED];
  } else if (s === TransactionDisplayStatus.PROCESSING) {
    return [
      StatusType.INITIATED,
      StatusType.PENDING,
      StatusType.SENT,
      StatusType.RECEIVED,
    ];
  } else {
    return s;
  }
};

const parseStatuses = (status: TransactionDisplayStatusType[] = []) => {
  return status?.map(mapStatus).flat();
};

const parseFilters = ({
  updated_at_after,
  updated_at_before,
  status,
  ...rest
}: any) => {
  const { atBefore, atAfter } = parseDates(updated_at_after, updated_at_before);
  const parsedStatuses = parseStatuses(status);

  return {
    ...rest,
    status: parsedStatuses,
    updated_at_after: atAfter,
    updated_at_before: atBefore,
  };
};

const download = function (data: any) {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.setAttribute('href', url);
  a.setAttribute('download', 'Multik - Exported Transactions.csv');
  a.click();
};

export const TransactionsFilters = ({
  onSubmit,
  minDate,
  maxDate,
  minAmount,
  maxAmount,
  disabled,
}: TransactionsFiltersProps) => {
  const [isExporting, toggleExporting] = useToggle(false);
  const { accounts, exchanges, appSettings } = useContext(AppContext);
  const dropdownFilters = getDropdownFilters(exchanges, accounts, appSettings);

  const [getFilters] = useFilters('transactions_filters');
  const filters = getFilters();
  const [isMobile, isAsideButtonChecked, handleAsideSize] = useIsMobile();

  function countElements(obj: any) {
    let count = 0;

    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        count += obj[key].length;
      } else {
        count++;
      }
    }

    return count;
  }

  const filtersCount = countElements(filters);

  const onClickExport = async () => {
    try {
      const filters = getFilters();
      const parsedFilters = parseFilters(filters);
      toggleExporting(true);
      const response = await requestGet(
        stringifyUrl('/transactions/download-csv/', parsedFilters),
      );
      download(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      toggleExporting(false);
    }
  };

  return (
    <S.Filters isMobile={isMobile} isAsideButtonChecked={isAsideButtonChecked}>
      {isMobile && (
        <S.FiltersData onClick={handleAsideSize} isMobile={isMobile}>
          <S.FiltersCounter>{filtersCount}</S.FiltersCounter>
          <S.FiltersIcon />
        </S.FiltersData>
      )}
      <S.Filter isMobile={isMobile}>
        <FilterSearch
          storageKey={storageKey}
          onSubmit={onSubmit}
          disabled={disabled}
        />
      </S.Filter>
      <S.Filter isMobile={isMobile}>
        <FilterDates
          min={minDate}
          max={maxDate}
          storageKey={storageKey}
          onSubmit={onSubmit}
          disabled={disabled}
        />
      </S.Filter>
      {dropdownFilters.map((filter) => {
        if (filter.field === 'is_internal_transfer') {
          return (
            <S.Filter isMobile={isMobile} key={`${storageKey}_${filter.field}`}>
              <FilterViewOptions
                onSubmit={onSubmit}
                disabled={disabled}
                storageKey={storageKey}
                {...filter}
              />
            </S.Filter>
          );
        } else {
          return (
            <S.Filter isMobile={isMobile} key={`${storageKey}_${filter.field}`}>
              <FilterDropdown
                onSubmit={onSubmit}
                disabled={disabled}
                storageKey={storageKey}
                {...filter}
              />
            </S.Filter>
          );
        }
      })}
      <S.Filter isMobile={isMobile}>
        {!isUndefined(minAmount) && !isUndefined(maxAmount) && (
          <FilterRange
            min={minAmount}
            max={maxAmount}
            storageKey={storageKey}
            onSubmit={onSubmit}
            disabled={
              disabled ||
              minAmount === maxAmount ||
              isUndefined(minAmount) ||
              isUndefined(maxAmount)
            }
          />
        )}
      </S.Filter>
      <S.Filter isMobile={isMobile}>
        <FilterStatus
          statuses={[
            TransactionDisplayStatus.AWAITING_CONFIRMATIONS,
            TransactionDisplayStatus.AWAITING_APPROVAL,
            TransactionDisplayStatus.PROCESSING,
            StatusType.CLOSED,
            StatusType.COMPLETED,
            StatusType.CANCELED,
            StatusType.REJECTED,
            StatusType.FAILED,
          ]}
          storageKey={storageKey}
          onSubmit={onSubmit}
          disabled={disabled}
        />
      </S.Filter>
      <S.FilterDivider></S.FilterDivider>
      <S.Filter isMobile={isMobile}>
        <div style={{ width: 'fit-content' }}>
          <S.ExportButton disabled={isExporting} onClick={onClickExport}>
            {!isExporting ? (
              'Export as CSV'
            ) : (
              <>
                Exporting
                <Loader small={true} position="relative" />
              </>
            )}
          </S.ExportButton>
        </div>
      </S.Filter>
      <S.AsideButton
        onClick={handleAsideSize}
        isMobile={isMobile}
        isAsideButtonChecked={isAsideButtonChecked}
      >
        <S.ArrowIconAside />
      </S.AsideButton>
    </S.Filters>
  );
};
