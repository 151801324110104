import { CurrencyInfoType } from '../types';

export const COINS_INFO: CurrencyInfoType[] = [
  {
    api_id: 'BTC',
    title: 'Bitcoin',
    logo_url: 'i/coins/btc.svg',
  },
  {
    api_id: 'ETH',
    title: 'Ethereum',
    logo_url: 'i/coins/eth.svg',
  },
  {
    api_id: 'GOR',
    title: 'Goerli Ethereum',
    logo_url: 'i/coins/eth.svg',
  },
  {
    api_id: 'EUR',
    title: 'EURS',
    logo_url: 'i/coins/eur.svg',
  },
  {
    api_id: 'EURS',
    title: 'EURS',
    logo_url: 'i/coins/eurs.svg',
  },
  {
    api_id: 'USD',
    title: 'Tether',
    logo_url: 'i/coins/usd.svg',
  },
  {
    api_id: 'USDT',
    title: 'Tether',
    logo_url: 'i/coins/usdt.svg',
  },
  {
    api_id: 'BUSD',
    title: 'Binance USD',
    logo_url: 'i/coins/busd.svg',
  },
  {
    api_id: 'AAVE',
    title: 'Aave',
    logo_url: 'i/coins/aave.svg',
  },
  {
    api_id: 'ADA',
    title: 'Ada',
    logo_url: 'i/coins/ada.svg',
  },
  {
    api_id: 'ATOM',
    title: 'Cosmos (ATOM)',
    logo_url: 'i/coins/atom.svg',
  },
  {
    api_id: 'AVAX',
    title: 'Avalanche',
    logo_url: 'i/coins/avax.svg',
  },
  {
    api_id: 'AXS',
    title: 'Axie Infinity',
    logo_url: 'i/coins/axs.svg',
  },
  {
    api_id: 'BAT',
    title: 'Basic Attention Token',
    logo_url: 'i/coins/bat.svg',
  },
  {
    api_id: 'COMP',
    title: 'Compound',
    logo_url: 'i/coins/comp.svg',
  },
  {
    api_id: 'CRV',
    title: 'Curve',
    logo_url: 'i/coins/crv.svg',
  },
  {
    api_id: 'DAI',
    title: 'Dai',
    logo_url: 'i/coins/dai.svg',
  },
  {
    api_id: 'DCR',
    title: 'Decred',
    logo_url: 'i/coins/dcr.svg',
  },
   {
    api_id: 'DOGE',
    title: 'Doge',
    logo_url: 'i/coins/doge.svg',
  },
   {
    api_id: 'DYDX',
    title: 'DyDx',
    logo_url: 'i/coins/dydx.svg',
  },
  {
    api_id: 'FET',
    title: 'Fetch.ai',
    logo_url: 'i/coins/fet.svg',
  },
  {
    api_id: 'FIL',
    title: 'Filecoin',
    logo_url: 'i/coins/fil.svg',
  },
  {
    api_id: 'FTM',
    title: 'Fantom',
    logo_url: 'i/coins/ftm.svg',
  },
  {
    api_id: 'FTT',
    title: 'FTX Token',
    logo_url: 'i/coins/ftt.svg',
  },
  {
    api_id: 'GRT',
    title: 'The Graph',
    logo_url: 'i/coins/grt.svg',
  },
  {
    api_id: 'ICP',
    title: 'Internet Computer',
    logo_url: 'i/coins/icp.svg',
  },
  {
    api_id: 'LEO',
    title: 'UNUS SED LEO',
    logo_url: 'i/coins/leo.svg',
  },
   {
    api_id: 'LINK',
    title: 'Link',
    logo_url: 'i/coins/link.svg',
  },
   {
    api_id: 'LTC',
    title: 'Litecoin',
    logo_url: 'i/coins/ltc.svg',
  },
   {
    api_id: 'MANA',
    title: 'Mana',
    logo_url: 'i/coins/mana.svg',
  },
   {
    api_id: 'MATIC',
    title: 'Matic',
    logo_url: 'i/coins/matic.svg',
  },
  {
    api_id: 'MKR',
    title: 'Maker',
    logo_url: 'i/coins/mkr.svg',
  },
  {
    api_id: 'OMG',
    title: 'OMG Network',
    logo_url: 'i/coins/omg.svg',
  },
   {
    api_id: 'ONT',
    title: 'Ontology',
    logo_url: 'i/coins/ont.svg',
  },
  {
    api_id: 'QTUM',
    title: 'Qtum',
    logo_url: 'i/coins/qtum.svg',
  },
  {
    api_id: 'REP',
    title: 'Rep',
    logo_url: 'i/coins/rep.svg',
  },
   {
    api_id: 'REN',
    title: 'Ren',
    logo_url: 'i/coins/ren.svg',
  },
  {
    api_id: 'SAND',
    title: 'Sand',
    logo_url: 'i/coins/sand.svg',
  },
  {
    api_id: 'SNX',
    title: 'Synthetix',
    logo_url: 'i/coins/snx.svg',
  },
  {
    api_id: 'SHIB',
    title: 'ShibaInu',
    logo_url: 'i/coins/shib.svg',
  },
  {
    api_id: 'STORJ',
    title: 'Storj',
    logo_url: 'i/coins/storj.svg',
  },
  {
    api_id: 'SUSHI',
    title: 'SushiSwap',
    logo_url: 'i/coins/sushi.svg',
  },
  {
    api_id: 'USDC',
    title: 'USD Coin',
    logo_url: 'i/coins/usdc.svg',
  },
  {
    api_id: 'CVX',
    title: 'Convex',
    logo_url: 'i/coins/cvx.svg',
  },
  {
    api_id: 'UNI',
    title: 'Uniswap',
    logo_url: 'i/coins/uni.svg',
  },
  {
    api_id: 'UST',
    title: 'TerraUSD',
    logo_url: 'i/coins/ust.png',
  },
  {
    api_id: 'XTZ',
    title: 'Tezos',
    logo_url: 'i/coins/xtz.svg',
  },
  {
    api_id: 'ZRX',
    title: '0x',
    logo_url: 'i/coins/zrx.svg',
  },
  {
    api_id: 'DASH',
    title: 'dash',
    logo_url: 'i/coins/dash.svg',
  },
  {
    api_id: 'DOT',
    title: 'dot',
    logo_url: 'i/coins/dot.svg',
  },
  {
    api_id: 'ETC',
    title: 'etc',
    logo_url: 'i/coins/etc.svg',
  },
  {
    api_id: 'IOTA',
    title: 'iota',
    logo_url: 'i/coins/miota.svg',
  },
  {
    api_id: 'NEO',
    title: 'neo',
    logo_url: 'i/coins/neo.svg',
  },
  {
    api_id: 'SOL',
    title: 'sol',
    logo_url: 'i/coins/sol.svg',
  },
  {
    api_id: 'XMR',
    title: 'xmr',
    logo_url: 'i/coins/xmr.svg',
  },
  {
    api_id: 'ZEC',
    title: 'zec',
    logo_url: 'i/coins/zec.svg',
  },
  {
    api_id: 'EGLD',
    title: 'Elrond',
    logo_url: 'i/coins/egld.svg',
  },
  {
    api_id: 'AMP',
    title: 'amp',
    logo_url: 'i/coins/amp.svg',
  },
  {
    api_id: 'FLOW',
    title: 'flow',
    logo_url: 'i/coins/flow.svg',
  },
  {
    api_id: 'GMX',
    title: 'gmx',
    logo_url: 'i/coins/gmx.svg',
  },
  {
    api_id: 'HNT',
    title: 'hnt',
    logo_url: 'i/coins/hnt.svg',
  },
  {
    api_id: 'IMX',
    title: 'imx',
    logo_url: 'i/coins/imx.svg',
  },
  {
    api_id: 'OP',
    title: 'op',
    logo_url: 'i/coins/op.svg',
  },
  {
    api_id: 'SKL',
    title: 'skl',
    logo_url: 'i/coins/skl.svg',
  },
  {
    api_id: 'UMA',
    title: 'uma',
    logo_url: 'i/coins/uma.svg',
  },
];

export function getCoinsInfoById(id: string): CurrencyInfoType | undefined {
  return COINS_INFO.find(
    (item) => item.api_id.toLowerCase() === id?.toLowerCase(),
  );
}
