// @ts-nocheck

import { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import * as S from './styled/main';
import * as A from './styled/assets';

import { getCoinsInfoById } from 'shared/constants/coins';
import Circle from 'shared/components/Сircle';
import { TabWrap, TabItem } from 'shared/components/Tab';

import { SafeStoreInstance } from '../mobx/SafeStore';
import { safeFormatUnits } from 'shared/helpers/format';
import { formatAmountNew, formatCurrency } from '../helpers';
import { Loader, Placeholder } from 'shared/components';

import { SettingsStoreInstance } from 'services/Settings';
import { getAvailableTokens } from './utilities';

type TabType = 'coins' | 'collectibles';
const ASSETS_TABS: {
  title: string;
  tab_alias: TabType;
}[] = [
  {
    title: 'Coins',
    tab_alias: 'coins',
  },
  {
    title: 'Collectibles',
    tab_alias: 'collectibles',
  },
];

const Assets = observer(() => {
  const { appSettings } = SettingsStoreInstance;
  const { currencies_primary, currencies_secondary } = appSettings;
  const [tab, setTab] = useState<TabType>('coins');
  const { coins, getCoins, isCoinsLoading } = SafeStoreInstance;

  useEffect(() => {
    getCoins();
  }, []);

  function handleSetTab(value: string) {
    setTab(value as TabType);
  }

  const filteredCoins = useMemo(
    () =>
      getAvailableTokens(
        [...currencies_primary, ...currencies_secondary],
        coins?.items,
        appSettings.gnosis_chain_id
      ),
    [appSettings, coins],
  );

  return (
    <A.Wrap
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <TabWrap style={{ paddingBottom: '15px', paddingLeft: '20px' }}>
        {ASSETS_TABS.map((item) => {
          return (
            <TabItem
              key={item?.tab_alias}
              active={String(tab) === item?.tab_alias}
              onClick={() => handleSetTab(item?.tab_alias)}
            >
              {item.title}
            </TabItem>
          );
        })}
      </TabWrap>
      <S.ContentWrap>
        {tab === 'coins' && !isCoinsLoading && filteredCoins?.length ? (
          <A.Table>
            <A.Header>
              <A.Col>Asset</A.Col>
              <A.Col>Balance</A.Col>
              <A.Col>Value</A.Col>
            </A.Header>
            {filteredCoins?.map((item, i) => {
              const coinBalance = formatAmountNew(
                safeFormatUnits(
                  item.balance,
                  item.tokenInfo.decimals,
                ).toString(),
              );
              const fiatBalance = formatCurrency(item.fiatBalance, 'USD');

              return (
                <A.Row key={i}>
                  <A.AssetCol>
                    {getCoinsInfoById(item.tokenInfo.symbol)?.logo_url ? (
                      <Circle
                        size={20}
                        image={
                          getCoinsInfoById(item.tokenInfo.symbol)?.logo_url
                        }
                      />
                    ) : null}
                    <span>{item.tokenInfo.name}</span>
                  </A.AssetCol>
                  <A.Col>
                    <A.Digits>{coinBalance}</A.Digits>
                    &nbsp;{item.tokenInfo.symbol}
                  </A.Col>
                  <A.Col>
                    <A.Digits>{fiatBalance}</A.Digits>
                  </A.Col>
                </A.Row>
              );
            })}
          </A.Table>
        ) : tab === 'coins' && !isCoinsLoading && !coins?.items?.length ? (
          <Placeholder height="140px" text="No coins available" />
        ) : tab === 'coins' && isCoinsLoading ? (
          <Loader />
        ) : null}
        {tab === 'collectibles' ? (
          <Placeholder height="140px" text="No collectibles available" />
        ) : null}
      </S.ContentWrap>
    </A.Wrap>
  );
});

export default Assets;
