import * as S from '../styled';
import queryString from 'query-string';
import { FC } from 'react';

interface QrModalProps {
  encoded: string;
  decoded?: string;
  comment?: any;
}

export const QrModal: FC<QrModalProps> = ({ encoded, decoded, comment }) => {
  return (
    <S.QrCodeWrap>
      <S.QrCodeVisual value={encoded} size={290} fgColor="#000000" />
      {comment && <S.QrCodeHint>{comment}</S.QrCodeHint>}
      {decoded && <S.QrCodeText>{decoded}</S.QrCodeText>}
    </S.QrCodeWrap>
  );
};
