import styled, { css } from 'styled-components/macro';
import { motion } from 'framer-motion';
import { ReactComponent as SvgQr } from 'shared/assets/icons/icon-qr-min.svg';

export const Wrap = styled(motion.div)`
  min-height: 100%;
  width: 100%;
  background: var(--base);
  position: relative;
  border: 1px solid var(--border);
  border-top: none;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  min-width: 455px;
  margin-bottom: 48.5px;
`;

export const Header = styled.header`
  padding: 24px 0 0 0;
  position: sticky;
  top: 0;
  left: 0;
  background: var(--base);
  z-index: 99;
  border: 1px solid var(--border);
  border-top: none;
  border-radius: 0 0 5px 5px;
  margin: 0 -1px;
`;

const IconCss = css`
  width: 15px;
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  fill: #0064ff;
  margin-right: 10px;
  cursor: pointer;
`;
export const IconQr = styled(SvgQr)`
  ${IconCss}

  fill: var(--object-secondary);

  &:hover {
    fill: var(--accent-primary);
  }
`;

export const Table = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;

  &:nth-child(even) {
    background-color: var(--highlight);
  }
`;

export const TableHeaderRow = styled(Row)`
  padding: 20px;
`;

export const TableHeaderTitle = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: var(--accent-primary);
  text-transform: uppercase;
  font-weight: 700;
  font-family: var(--font-main);
`;

export const Col = css`
  padding: 10px 0;
  width: 100%;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  font-family: var(--font-monospaced);
`;

export const ColExchange = styled.div`
  ${Col}
  min-width: 100px;
  max-width: 130px;

  @media (max-width: 1125px) {
    max-width: fit-content;
  }
`;

export const TippyText = styled.div`
  font-family: Roboto Mono, Consolas, Menlo, Monaco, Lucida Console,
    Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New,
    monospace;
  font-size: 11px;
  color: var(--object-primary);
`;

export const ColAccount = styled.div`
  ${Col}
  padding: 10px 5px 10px 0;
  flex-direction: column;
  align-items: flex-start;
  min-width: 120px;
  max-width: 200px;

  @media (max-width: 1125px) {
    max-width: 160px;
  }
`;

export const ColMasterInfo = styled.div``;

export const ColSubInfo = styled.div`
  color: var(--object-secondary);
`;

export const ColAlias = styled.div`
  ${Col}
  min-width: 330px;
  max-width: 330px;

  @media (max-width: 890px) {
    max-width: 85px;
    min-width: 85px;
  }
`;

export const ColCoin = styled.div`
  ${Col}
  min-width: 80px;
  max-width: 130px;

  @media (max-width: 890px) {
    max-width: fit-content;
  }
`;
export const DepositAddress = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ColDeposit = styled.div`
  ${Col}
  width: auto;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--font-monospaced);
  min-width: 75px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;

  @media (max-width: 1125px) {
    min-width: 65px;
    max-width: 235px;
    cursor: pointer;
  }

  @media (max-width: 890px) {
    max-width: 120px;
  }
`;

export const ColConfirmed = styled.div`
  ${Col}
  max-width: 60px;
  min-width: 60px;
  padding-left: 20px;
`;

export const CoinLogo = styled.div`
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin: 0 10px 0 0;
`;

export const ExchangeLogo = styled.div`
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin: 0 10px 0 0;
`;

export const ExchangeName = styled.span`
  text-transform: uppercase;
`;

export const Confirmed = styled.div`
  color: var(--success);
`;

export const Unconfirmed = styled.div`
  color: var(--error);
`;
