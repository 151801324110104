import {
  AccountsStoreInstance,
  CurrenciesStoreInstance,
  SettingsStoreInstance,
} from 'services';
import * as S from '../styled';
import { OverviewStoreInstance } from 'features/Overview/mobx/OverviewStore';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { useState } from 'react';
import { getExchangeInfoById } from 'shared/constants/exchanges';
import { format, isToday, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Tippy from '@tippyjs/react';
import { TableCoinCell } from './TableCoinCell';
import { CurrencyType } from 'shared/types';
import { toJS } from 'mobx';

const overviewFilters = 'overview_filters';
const balanceTimeFormat = 'HH:mm';
const balanceDayFormat = 'dd.MM.yyyy';

export const OverviewExchangeRow = ({
  data,
  toggleAccounts,
  availableCurrencies,
  initialCurrencies,
  cell,
  isOpen,
  setCell,
  isCryptoTab,
  chosenCurrency,
}: any) => {
  const { view, viewInfo, chosenRate } = OverviewStoreInstance;
  const { exchangesStatuses } = AccountsStoreInstance;
  const { rates, getCurrencyPrecision, getCurrencyValue } =
    CurrenciesStoreInstance;

  const exchangeName = data.exchange;

  const exchangeInfo = getExchangeInfoById(exchangeName);

  const statusData = exchangesStatuses.find(
    (status) => status.exchange === exchangeInfo?.api_id,
  );
  const exchangeStatus = statusData?.ok;

  const info = data.info;

  const isTodayBalanceDate = isToday(new Date(info.balance_updated_at));

  const currentBalanceFormat = !isTodayBalanceDate
    ? balanceDayFormat
    : balanceTimeFormat;

  const formattedBalanceDate = info.balance_updated_at
    ? format(
        utcToZonedTime(parseISO(info.balance_updated_at), 'UTC'),
        currentBalanceFormat,
      )
    : '';

  const sortedCurrencies = [
    //@ts-ignore
    ...new Set([...initialCurrencies, ...chosenCurrency]),
  ];

  return (
    <S.Row key={exchangeName} isExchange={data.isExchange} index={data.index}>
      <S.LayoutWrap>
        <S.ExchangeCell
          onClick={() => toggleAccounts(exchangeName)}
          active={
            exchangeName.toLowerCase() === cell?.split('-')[0].toLowerCase()
          }
        >
          <S.ExchangeTitle isExchange={data.isExchange}>
            <S.ExchangeLogo
              isExchange={true}
              style={{
                backgroundImage: `url(${exchangeInfo?.logo_url})`,
              }}
            />
            <S.ExchangeName>{exchangeName}</S.ExchangeName>
            {isOpen ? <S.ChevronUp /> : <S.ChevronDown />}
            {!exchangeStatus && statusData && (
              <Tippy
                theme="transparent"
                placement="top"
                content="Some functionality could be unavailable now"
              >
                <S.WarningLogo />
              </Tippy>
            )}
          </S.ExchangeTitle>
          <S.ExchangeBalanceStatus>
            {info.is_balance_outdated || !info.balance_updated_at ? (
              <S.IconBalanceWarning />
            ) : null}
            <S.ExchangeBalanceUpdated
              isOutdated={info.is_balance_outdated || !info.balance_updated_at}
            >
              {info.balance_updated_at ? (
                <span>
                  Updated: {formattedBalanceDate}{' '}
                  {currentBalanceFormat && 'UTC'}
                </span>
              ) : (
                <span>Never updated</span>
              )}
            </S.ExchangeBalanceUpdated>
          </S.ExchangeBalanceStatus>
        </S.ExchangeCell>
        {sortedCurrencies
          .filter((currency) => chosenCurrency.includes(currency))
          .map((availableCurrency: string) => (
            <TableCoinCell
              key={availableCurrency}
              availableCurrency={availableCurrency as CurrencyType}
              data={data}
              cell={cell}
              chosenRate={chosenRate}
              exchangeName={exchangeName}
              getCurrencyPrecision={getCurrencyPrecision}
              getCurrencyValue={getCurrencyValue}
              isCryptoTab={isCryptoTab}
              rates={rates}
              setCell={setCell}
              view={view}
              viewInfo={viewInfo}
            />
          ))}
      </S.LayoutWrap>
      <TableCoinCell
        availableCurrency={'TOTAL'}
        data={data}
        cell={cell}
        chosenRate={chosenRate}
        exchangeName={exchangeName}
        getCurrencyPrecision={getCurrencyPrecision}
        getCurrencyValue={getCurrencyValue}
        isCryptoTab={isCryptoTab}
        rates={rates}
        setCell={setCell}
        view={view}
        viewInfo={viewInfo}
      />
    </S.Row>
  );
};
