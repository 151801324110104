import * as S from './styled';
import { ProtectedArea } from 'shared/components';
import { useContext } from 'react';
import { TransferHeader } from './newComponents/Header';
import AppContext from 'shared/contexts/AppContext';
import { useIsMobile } from 'shared/hooks/useIsMobile';
import { TransferFilters } from './newComponents/Filters';
import { Columns } from './newComponents/Columns';
import { TransferArea } from './newComponents/TransferArea';
import { TransferContext } from 'features/Transfer/context/TransferContext';

export const Transfer = () => {
  const { isAsideMobileType } = useContext(AppContext);
  const [isMobile] = useIsMobile();

  return (
    <S.TransferWrap ismobile={isMobile ? 'true' : undefined}>
      <ProtectedArea displayMessage every={['accounts.view_account']}>
        <S.Wrap
          ismobile={isMobile ? 'true' : undefined}
          isasidemobiletype={isAsideMobileType ? 'true' : undefined}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
        >
          <TransferHeader />
          <Columns />
          <TransferArea />
        </S.Wrap>
        <TransferFilters />
      </ProtectedArea>
    </S.TransferWrap>
  );
};
