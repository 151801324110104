import {
  TransactionDisplayStatus,
  TransactionDisplayStatusType,
  TransactionStatusType,
  TransactionVote,
} from 'services/Transactions/types';
import { capitalize, formatAccount } from './format';
import {
  CurrencyType,
  UserInfoType,
  StatusType,
  AccountType,
  Threshold,
  ExchangeApiIdType,
  WalletType,
} from 'shared/types';
import { lime } from '@mui/material/colors';

export const canExecuteTransaction = (
  userWeight: any,
  proposalVotes: any,
  requiredVotes: any,
) => {
  return Number(userWeight) + Number(proposalVotes) >= Number(requiredVotes);
};

export const canRejectTransaction = (
  userWeight: any,
  proposalVotes: any,
  requiredRejections: any,
) => {
  return (
    Number(proposalVotes) - Number(userWeight) <= Number(requiredRejections)
  );
};

export const getTransactionDisplayStatus = (
  status: TransactionStatusType,
  userVote?: TransactionVote,
): TransactionDisplayStatusType => {
  if ([StatusType.QUEUED, StatusType.UNCONFIRMED].includes(status)) {
    if (status === StatusType.QUEUED && userVote) {
      if (userVote.vote < 0) {
        return capitalize(TransactionDisplayStatus.REJECTED + ' by you');
      } else {
        return capitalize(TransactionDisplayStatus.APPROVED + ' by you');
      }
    } else if (status === StatusType.UNCONFIRMED) {
      return capitalize(TransactionDisplayStatus.AWAITING_APPROVAL);
    } else {
      return capitalize(TransactionDisplayStatus.AWAITING_CONFIRMATIONS);
    }
  } else if (
    [
      StatusType.INITIATED,
      StatusType.PENDING,
      StatusType.SENT,
      StatusType.RECEIVED,
    ].includes(status)
  ) {
    return capitalize(TransactionDisplayStatus.PROCESSING);
  } else {
    return capitalize(status);
  }
};

export const aboutToExceedSingleLimit = (
  a: number | string,
  currency: CurrencyType | undefined,
  user: UserInfoType | null,
  rates: any,
) => {
  const amount = Number(a);

  if (amount <= 0 || !currency || !user || !rates) return false;

  const limit = Number(user?.single_transaction_limit);
  const rate = Number(rates?.['USD'][currency]);

  return amount * rate > limit;
};

export const aboutToExceedLimit = (
  a: number | string,
  currency: CurrencyType | undefined,
  user: UserInfoType | null,
  rates: any,
) => {
  const amount = Number(a);

  if (amount <= 0 || !currency || !user || !rates) return false;

  const type = user.period_limits.WEEK ? 'WEEK' : 'DAY';
  const limit = Number(user?.period_limits[type]);
  const payments = Number(user?.period_payments[type]);
  const rate = Number(rates?.['USD'][currency]);

  return payments + amount * rate > limit;
};

export const getThreshold = (
  account: Nullable<AccountType>,
  currency: string,
): Nullable<Threshold> => {
  if (!account) return null;
  const thresholds = {
    ...account?.thresholds?.total,
    ...account?.thresholds?.specific,
  };

  // @ts-ignore
  return thresholds[currency] ? thresholds[currency] : null;
};

export const aboutToViolateMinThreshold = (
  threshold: Nullable<Threshold>,
  amount: string,
) => {
  if (!threshold || threshold.min_amount === null) return false;
  return threshold.current_amount - Number(amount) < threshold.min_amount;
};

export const aboutToViolateMaxThreshold = (
  threshold: Nullable<Threshold>,
  amount: string,
) => {
  if (!threshold || threshold.max_amount === null) return false;
  return threshold.current_amount + Number(amount) > threshold.max_amount;
};

const AVAILABLE_TYPES: { [key: string]: string[] } = {
  BINANCE: ['spot', 'funding'],
  BITFINEX: ['exchange', 'margin', 'funding'],
  HITBTC: ['wallet'],
  OKCOIN: ['funding'],
  OKX: ['funding'],
};

export const isDepositAddressAvailable = (
  exchange: ExchangeApiIdType,
  type: string,
  addresses?: [],
) => {
  return Boolean(
    !AVAILABLE_TYPES[exchange] || AVAILABLE_TYPES[exchange].includes(type),
  );
};
