import { AppSettingsType } from '../../shared/types';

export const settings: AppSettingsType = {
  application_direct_link: '',
  application_playmarket_link: '',
  currencies_primary: ['BTC', 'ETH', 'EURS', 'USDT', 'BUSD', 'EUR', 'USD'],
  gnosis_enabled: false,
  proposal_votes_value_to_approve: 3,
  proposal_votes_value_to_reject: 0,
  gnosis_chain_id: '5',
  gnosis_node: 'https://safe-client.gnosis.io',
  currencies_secondary: [
    'AAVE',
    'ATOM',
    'AVAX',
    'AXS',
    'BAT',
    'COMP',
    'DAI',
    'DCR',
    'FET',
    'FIL',
    'FTM',
    'FTT',
    'GRT',
    'ICP',
    'LEO',
    'MKR',
    'OMG',
    'QTUM',
    'SNX',
    'SUSHI',
    'USDC',
    'UNI',
    'UST',
    'XTZ',
    'ZRX',
    'CVX',
    'ZEC',
  ],
  google_auth_key:
    '168698886450-btphjocqagsncibppfvbqsg6gcdam61s.apps.googleusercontent.com',
  is_hotp_enabled: true,
  overview_decimals: {
    default: {
      crypto: 5,
      fiat: 0,
      usd_k: 2,
      usd_m: 1,
    },
    BTC: 5,
  },
};
