import { CurrencyType } from 'shared/types';
import { getCoinsInfoById } from 'shared/constants/coins';

import { TransferFiltersType } from '../../../types';
import * as S from './styled';

function FlatCurrencySelector({
  availableCurrencies,
  filters,
  handleChangeCurrency,
  isCurrencyDropdownOpen,
  minValue,
  isMobile,
}: {
  availableCurrencies: CurrencyType[];
  chosenCurrency: string;
  filters: TransferFiltersType;
  handleChangeCurrency: any;
  handleToggleCurrency: any;
  isCurrencyDropdownOpen: boolean;
  minValue: boolean;
  isMobile: boolean;
}) {
  return (
    <S.CurrencySelectWrap opened={isCurrencyDropdownOpen} isMobile={isMobile}>
      <S.CurrencyOptions>
        {[...availableCurrencies]
          .sort((a, b) => a.localeCompare(b))
          .map((availableCurrency) => {
            const isActive = filters.currencies.includes(availableCurrency);
            const logoUrl =
              getCoinsInfoById(availableCurrency)?.logo_url || null;

            return (
              <S.CurrencyOption
                className={isActive ? 'active' : ''}
                key={availableCurrency}
                onClick={() => handleChangeCurrency(availableCurrency)}
                active={isActive}
              >
                {logoUrl ? (
                  <S.CurrencyLogo
                    style={{
                      backgroundImage: `url(${logoUrl})`,
                    }}
                  />
                ) : null}
                {availableCurrency}
              </S.CurrencyOption>
            );
          })}
      </S.CurrencyOptions>
    </S.CurrencySelectWrap>
  );
}

export { FlatCurrencySelector };
