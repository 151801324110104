import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { requestPatch } from 'shared/axios';
import { notify } from 'shared/components/Notification/notify';
import AppContext from 'shared/contexts/AppContext';
import { StatusProposalChange, StatusProposalType } from 'shared/types';

export const useChangeProposalStatus = ({
  onClose,
  proposal,
  setIsUpdating,
  setIsOpen,
  setHighlighted,
}: {
  onClose?: any;
  proposal: any;
  setIsUpdating?: any;
  setIsOpen?: any;
  setHighlighted?: any;
}) => {
  const { scrollToTop, transactionsTab } = useContext(AppContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['changeProposalStatus'],
    mutationFn: async ({
      status,
      otp,
      tfa,
    }: {
      status: StatusProposalChange;
      otp?: string;
      tfa?: string;
    }) => {
      setIsUpdating(true);
      return await requestPatch(
        `/transactions/${proposal.id}/${status}/`,
        {
          ...(tfa && { tfa }),
        },
        {
          headers: {
            ...(otp && { 'X-AUTH-HOTP': otp }),
          },
        },
      );
    },
    // @ts-ignore
    onSettled: async ({ data }, _, { status }) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['transactions_history'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['transactions_queue'],
        }),
      ]);

      setIsUpdating(false);
      if (setHighlighted) {
        setTimeout(() => setHighlighted(true), 500);
        setTimeout(() => setHighlighted(false), 4500);
      }
      if (onClose) onClose();

      if (data.status === StatusProposalType.APPROVED) {
        notify({
          type: 'success',
          header: 'Proposal was approved',
          text:
            transactionsTab === 'proposals'
              ? 'Click here to view detailed information'
              : 'You can find the approved proposal on the Proposals tab',
          onClick:
            transactionsTab === 'proposals'
              ? () => {
                  if (setIsOpen) {
                    setIsOpen(true);
                    scrollToTop();
                  }
                }
              : undefined,
        });
      } else if (data.status === StatusProposalType.REJECTED) {
        notify({
          type: 'error',
          header: 'Proposal was rejected',
          text:
            transactionsTab === 'proposals'
              ? 'Click here to view detailed information'
              : 'You can find the rejected proposal on the Proposals tab',
          onClick:
            transactionsTab === 'proposals'
              ? () => {
                  if (setIsOpen) {
                    setIsOpen(true);
                    scrollToTop();
                  }
                }
              : undefined,
        });
      } else {
        notify({
          type: 'warning',
          header: `You voted ${
            status === 'vote-down' ? 'down' : 'up'
          } the proposal`,
        });
      }
    },
  });
};
