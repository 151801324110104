import { CurrencyType } from 'shared/types';
import Decimal from 'decimal.js-light';
import Tippy from '@tippyjs/react';

import * as S from '../../styled';
import { ViewType } from 'features/Overview/types';

export const TableCoinCell = ({
  availableCurrency,
  data,
  cell,
  chosenRate,
  exchangeName,
  getCurrencyPrecision,
  getCurrencyValue,
  isCryptoTab,
  rates,
  setCell,
  view,
  viewInfo,
}: {
  availableCurrency: CurrencyType | 'TOTAL';
  data: any;
  cell: string;
  chosenRate: any;
  exchangeName: string;
  getCurrencyPrecision: any;
  getCurrencyValue: any;
  isCryptoTab: boolean;
  rates: any;
  setCell: any;
  view: string;
  viewInfo: {
    id: string;
    label: ViewType;
    currency: CurrencyType;
    currency_label: string;
    divisor: number;
    divisor_label: any;
  };
}) => {
  const isTotal = availableCurrency === 'TOTAL';

  const coinBalance = data?.values[availableCurrency] || null;

  const calculatedBalance = coinBalance ? { ...coinBalance } : null;

  if (
    !isTotal &&
    coinBalance &&
    !isCryptoTab &&
    rates[chosenRate][availableCurrency]
  ) {
    calculatedBalance.available = new Decimal(coinBalance.available)
      .mul(rates[chosenRate][availableCurrency])
      .div(viewInfo.divisor)
      .toFixed();
    calculatedBalance.total = new Decimal(coinBalance.total)
      .mul(rates[chosenRate][availableCurrency])
      .div(viewInfo.divisor)
      .toFixed();
  }

  const cellID = exchangeName + '-' + availableCurrency;

  const isRateAvailable =
    !isTotal &&
    Boolean(
      rates[chosenRate] && rates[chosenRate][availableCurrency]
        ? rates[chosenRate][availableCurrency]
        : false,
    );

  const isRateError =
    !isTotal && !isRateAvailable && calculatedBalance?.total > 0;

  const valueCurrency =
    viewInfo.id === 'usd_k' || viewInfo.id === 'usd_m'
      ? viewInfo.id
      : isTotal || !isCryptoTab
      ? chosenRate
      : availableCurrency;

  return calculatedBalance ? (
    <S.DataCell
      key={cellID}
      onClick={() => setCell(cellID)}
      active={cell === cellID}
      total={availableCurrency === 'TOTAL'}
    >
      <Tippy
        theme="transparent"
        placement="right"
        content={
          isCryptoTab ? (
            <>
              {isRateError ? (
                <S.ErrorHintText>Rate is not available</S.ErrorHintText>
              ) : (
                <>
                  <S.HintText>Total</S.HintText>
                  <S.HintText>Available</S.HintText>
                </>
              )}
            </>
          ) : (
            <>
              {isRateError ? (
                <S.ErrorHintText>Rate is not available</S.ErrorHintText>
              ) : (
                <>
                  <S.HintText>
                    Total:{' '}
                    {coinBalance?.total > 0
                      ? getCurrencyValue({
                          currency: (isTotal
                            ? viewInfo.id
                            : availableCurrency) as CurrencyType,
                          value: String(coinBalance?.total),
                        })
                      : 0}
                    {` ${isTotal ? view : availableCurrency}`}
                  </S.HintText>
                  <S.HintText>
                    Available:{' '}
                    {coinBalance?.available > 0
                      ? getCurrencyValue({
                          currency: (isTotal
                            ? viewInfo.id
                            : availableCurrency) as CurrencyType,
                          value: String(coinBalance?.available),
                        })
                      : 0}
                    {` ${isTotal ? view : availableCurrency}`}
                  </S.HintText>
                </>
              )}
            </>
          )
        }
      >
        <span>
          <S.Total bold={isTotal} error={isRateError}>
            {(viewInfo.id === 'crypto' && availableCurrency !== 'TOTAL') ||
            isRateError ? null : (
              <S.CurrencyLabelWrap>
                {viewInfo.currency_label}
              </S.CurrencyLabelWrap>
            )}
            {calculatedBalance?.total > 0
              ? getCurrencyValue({
                  currency: valueCurrency as CurrencyType,
                  value: String(
                    isRateError && viewInfo.id !== 'crypto'
                      ? parseInt(calculatedBalance?.total)
                      : calculatedBalance?.total,
                  ),
                  minprecision: isRateError
                    ? undefined
                    : getCurrencyPrecision(valueCurrency as CurrencyType),
                })
              : 0}
            {viewInfo.id === 'crypto' || isRateError
              ? null
              : viewInfo?.divisor_label}
          </S.Total>
          <S.Available bold={availableCurrency === 'TOTAL'} error={isRateError}>
            {(viewInfo.id === 'crypto' && availableCurrency !== 'TOTAL') ||
            isRateError ? null : (
              <S.CurrencyLabelWrap>
                {viewInfo.currency_label}
              </S.CurrencyLabelWrap>
            )}
            {calculatedBalance?.available > 0
              ? getCurrencyValue({
                  currency: valueCurrency as CurrencyType,
                  value: String(
                    isRateError && viewInfo.id !== 'crypto'
                      ? parseInt(calculatedBalance?.available)
                      : calculatedBalance?.available,
                  ),
                  minprecision: isRateError
                    ? undefined
                    : getCurrencyPrecision(valueCurrency as CurrencyType),
                })
              : 0}
            {viewInfo.id === 'crypto' || isRateError
              ? null
              : viewInfo?.divisor_label}
          </S.Available>
        </span>
      </Tippy>
    </S.DataCell>
  ) : (
    <S.DataCell
      key={cellID}
      active={false}
      total={availableCurrency === 'TOTAL'}
    >
      <S.Total bold={false}>-</S.Total>
    </S.DataCell>
  );
};
