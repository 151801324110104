import { FC, memo, useContext, useMemo, useState } from 'react';

import * as S from 'features/Transactions/view/styled';
import { CurrencyType, ExchangeApiIdType, StatusType } from 'shared/types';
import AppContext from 'shared/contexts/AppContext';
import { dateAgo, formattedDate } from 'shared/helpers/date';
import {
  Transaction,
  TransactionStatusType,
  TransactionVote,
  TransactionWithdrawalStatusType,
} from 'services/Transactions/types';
import Tippy from '@tippyjs/react';
import { isUndefined } from 'shared/helpers/strings';
import {
  aboutToExceedLimit,
  aboutToExceedSingleLimit,
  canExecuteTransaction,
  canRejectTransaction,
  getThreshold,
} from 'shared/helpers/transaction';
import { ThresholdViolationInfo } from './ThresholdViolationInfo';

const StatusOrder = {
  [StatusType.QUEUED]: 0,
  [StatusType.CLOSED]: 0,
  [StatusType.INITIATED]: 1,
  [StatusType.UNCONFIRMED]: 2,
  [StatusType.PENDING]: 3,
  [StatusType.SENT]: 4,
  [StatusType.RECEIVED]: 5,
  [StatusType.COMPLETED]: 6,
  [StatusType.CANCELED]: 6,
  [StatusType.REJECTED]: 6,
  [StatusType.FAILED]: 6,
};

const hasPassed = (
  status: TransactionStatusType,
  target: TransactionStatusType,
) => {
  return StatusOrder[status] >= StatusOrder[target];
};

interface LineData {
  title: string;
  status: string;
  time?: string;
  comment?: string;
}
interface StatusBar {
  id: string;
  isVisible: (tx: Transaction) => boolean;
  getData: (tx: Transaction, votesToApprove?: number) => LineData;
  lines?: StatusBar[];
}

const emailRequiredExchanges: ExchangeApiIdType[] = [
  'BINANCE',
  'BITFINEX',
  'DERIBIT',
  'HITBTC',
  'OKCOIN',
];

const requiresEmailConfirmation = (exchange?: string) => {
  return emailRequiredExchanges.includes(exchange as ExchangeApiIdType);
};

const LINES: StatusBar[] = [
  {
    id: 'confirmations',
    isVisible: (tx) => !isUndefined(tx.votes) && tx?.votes?.length > 0,
    getData: (tx: Transaction, votesToApprove?: number) => {
      const shownVotes =
        votesToApprove &&
        Math.max(0, Math.min(tx.sum_of_votes, votesToApprove));

      if (tx.status === StatusType.CLOSED) {
        return {
          title: 'Confirmations',
          status: 'failed',
          comment: `(${shownVotes} out of ${votesToApprove})`,
        };
      } else if (hasPassed(tx.status, StatusType.INITIATED)) {
        return {
          title: 'Confirmations',
          status: 'completed',
          comment: `(${shownVotes} out of ${votesToApprove})`,
        };
      } else {
        return {
          title: 'Confirmations',
          status: 'active',
          comment: `(${shownVotes} out of ${votesToApprove})`,
        };
      }
    },
  },
  {
    id: 'processing',
    isVisible: (tx: Transaction) => tx.status !== 'CLOSED',
    getData: (tx: Transaction) => {
      if (!hasPassed(tx.status, StatusType.INITIATED)) {
        return {
          title: 'Execution',
          status: 'inactive',
        };
      } else if (
        hasPassed(tx.status, StatusType.COMPLETED) &&
        tx.status === StatusType.COMPLETED
      ) {
        return {
          title: 'Confirmed',
          status: 'completed',
        };
      } else {
        if (tx.status === StatusType.UNCONFIRMED) {
          return {
            title: 'Processing',
            status: 'awaiting',
          };
        } else {
          return {
            title: 'Processing',
            status: hasPassed(tx.status, StatusType.COMPLETED)
              ? 'failed'
              : 'active',
          };
        }
      }
    },
    lines: [
      {
        id: 'confirmation',
        isVisible: (tx) =>
          hasPassed(tx.status, StatusType.UNCONFIRMED) &&
          requiresEmailConfirmation(tx?.account_from?.exchange) &&
          !!tx.account_from &&
          !!tx.account_to &&
          ![StatusType.CANCELED, StatusType.REJECTED].includes(
            tx.withdrawal_status?.toUpperCase() as TransactionWithdrawalStatusType,
          ),
        getData: (tx: Transaction) => {
          if (hasPassed(tx.status, StatusType.PENDING)) {
            return {
              title: 'Confirmed on exchange',
              status: 'completed',
            };
          } else {
            return {
              title: 'Unconfirmed on exchange',
              status: 'awaiting',
              comment: 'Approve withdrawal in email',
            };
          }
        },
      },
      {
        id: 'withdrawal',
        isVisible: (tx) =>
          hasPassed(tx.status, StatusType.UNCONFIRMED) && !!tx.account_from,
        getData: (tx: Transaction) => {
          if (
            hasPassed(tx.status, StatusType.COMPLETED) &&
            tx.withdrawal_status === 'Rejected'
          ) {
            return {
              title: 'Withdrawal rejected',
              status: 'failed',
              time: tx.withdrawal_updated_at,
            };
          } else if (
            hasPassed(tx.status, StatusType.COMPLETED) &&
            tx.withdrawal_status === 'Canceled'
          ) {
            return {
              title: 'Withdrawal canceled',
              status: 'failed',
              time: tx.withdrawal_updated_at,
            };
          } else if (hasPassed(tx.status, StatusType.SENT)) {
            return {
              title: 'Withdrawal sent',
              status: 'completed',
              time: tx.withdrawal_updated_at,
            };
          } else if (hasPassed(tx.status, StatusType.PENDING)) {
            return {
              title: 'Withdrawal pending',
              status: 'active',
              time: tx.withdrawal_updated_at,
            };
          } else {
            return {
              title: 'Withdrawal pending',
              status: 'inactive',
              time: tx.withdrawal_updated_at,
            };
          }
        },
      },
      {
        id: 'deposit',
        isVisible: (tx) =>
          hasPassed(tx.status, StatusType.UNCONFIRMED) &&
          !!tx.account_to &&
          (!isUndefined(tx.deposit_status) ||
            tx.status !== StatusType.COMPLETED) &&
          !(
            hasPassed(tx.status, StatusType.COMPLETED) &&
            (tx.withdrawal_status === 'Rejected' ||
              tx.withdrawal_status === 'Canceled')
          ),
        getData: (tx: Transaction) => {
          if (
            hasPassed(tx.status, StatusType.COMPLETED) &&
            tx.deposit_status === 'Failed'
          ) {
            return {
              title: 'Deposit failed',
              status: 'failed',
              time: tx.deposit_updated_at,
            };
          } else if (
            hasPassed(tx.status, StatusType.COMPLETED) &&
            tx.deposit_status === 'Completed'
          ) {
            return {
              title: 'Deposit confirmed',
              status: 'completed',
              time: tx.deposit_updated_at,
            };
          } else if (
            hasPassed(tx.status, StatusType.RECEIVED) &&
            tx.deposit_status === 'Pending'
          ) {
            return {
              title: 'Deposit received',
              status: 'active',
              time: tx.deposit_updated_at,
            };
          } else {
            return {
              title: 'Deposit received',
              status: 'inactive',
              time: tx.deposit_updated_at,
            };
          }
        },
      },
    ],
  },
  {
    id: 'completion',
    isVisible: (tx: Transaction) =>
      tx.status !== 'QUEUED' &&
      !(
        hasPassed(tx.status, StatusType.COMPLETED) && tx.status !== 'COMPLETED'
      ) &&
      tx.status !== 'CLOSED',
    getData: (tx: Transaction) => {
      if (tx.status === StatusType.COMPLETED) {
        return {
          title: 'Completed',
          status: 'completed',
          time: tx.updated_at,
        };
      } else {
        return {
          title: 'Completed',
          status: 'inactive',
        };
      }
    },
  },
];

interface StatusLinesProps {
  tx: Transaction;
}
const StatusLines: FC<StatusLinesProps> = memo(({ tx }) => {
  return (
    <>
      {LINES.map((line, index) => (
        <StatusLine key={index} line={line} tx={tx} />
      ))}
    </>
  );
});

interface CreatedLineProps {
  creator: string;
  createdAt: string;
}
const CreatedLine: FC<CreatedLineProps> = memo(({ creator, createdAt }) => {
  return (
    <S.StatusBarLineItem className={'completed'}>
      <S.StatusBarLineRow>
        <S.StatusBarLineTitle>
          <span>Created</span> {creator ? `by ${creator}` : ''}
        </S.StatusBarLineTitle>

        <StatusLineDate date={createdAt} />
      </S.StatusBarLineRow>
    </S.StatusBarLineItem>
  );
});

interface VoteButtonsProps {
  voteUp: any;
  voteDown: any;
  voteError: any;
  tx: Transaction;
}
const VoteButtons: FC<VoteButtonsProps> = memo(
  ({ voteUp, voteDown, tx, voteError }) => {
    const { currency, amount } = tx;
    const sumOfVotes = tx?.sum_of_votes;
    const votes = tx?.votes;

    const { user, appSettings, rates } = useContext(AppContext);

    const userVote = useMemo(
      () => votes?.find(({ user: name }) => name === user?.email),
      [user, votes],
    );

    const aboutToExecute = canExecuteTransaction(
      user?.proposal_vote_weight,
      sumOfVotes,
      appSettings.proposal_votes_value_to_approve,
    );
    const aboutToReject = canRejectTransaction(
      user?.proposal_vote_weight,
      sumOfVotes,
      appSettings.proposal_votes_value_to_reject,
    );
    const aboutToExceed = aboutToExceedLimit(
      amount,
      currency as CurrencyType,
      user,
      rates,
    );
    const aboutToExceedSingleTransaction = aboutToExceedSingleLimit(
      amount,
      currency as CurrencyType,
      user,
      rates,
    );

    const alreadyVoted = useMemo(() => {
      if (!votes) {
        return false;
      }

      return !!userVote;
    }, [userVote, votes]);

    const isInvalid = !tx.wallet_from || !tx.wallet_to;
    const invalidAccount = useMemo(() => {
      if (!tx.wallet_from && !tx.wallet_to) {
        return 'both sender and deposit accounts have';
      } else if (!tx.wallet_from) {
        return 'the sender account has';
      } else if (!tx.wallet_to) {
        return 'the deposit account has';
      }
    }, []);

    if (alreadyVoted) return null;

    if (aboutToExceedSingleTransaction) {
      return (
        <S.VoteWeightWrap>
          <p>
            Your single transaction limit is insufficient for voting on this
            transaction. Please contact the administrator to request an
            increase.
          </p>
        </S.VoteWeightWrap>
      );
    }

    if (aboutToExceed) {
      return (
        <S.VoteWeightWrap>
          <p>
            Your payment limit is insufficient for voting on this transaction.
            Please contact the administrator at{' '}
            <a href="mailto:support@multik.io">support@multik.io</a> to request
            an increase.
          </p>
        </S.VoteWeightWrap>
      );
    }

    return (
      <S.VoteWeightWrap>
        {user?.proposal_vote_weight ? (
          <p>You have {user?.proposal_vote_weight} vote weight</p>
        ) : (
          <p>
            Your vote weight is not set. Please contact the administrator at
            support@multik.io
          </p>
        )}
        {aboutToExecute && <ThresholdViolationInfo tx={tx} />}
        <S.VoteUpButton
          onClick={voteUp}
          disabled={!user?.proposal_vote_weight || isInvalid}
        >
          <S.VoteUpIcon />
          vote up {aboutToExecute && '& execute'}
        </S.VoteUpButton>
        <S.VoteDownButton
          onClick={voteDown}
          disabled={!user?.proposal_vote_weight}
        >
          <S.VoteDownIcon />
          vote down {aboutToReject && '& close'}
        </S.VoteDownButton>
        {isInvalid && (
          <p>
            This transaction cannot be sent because {invalidAccount} been
            removed from the Enclave. You can vote down and close this
            transaction.
          </p>
        )}
        {voteError && (
          <S.ErrorText>
            {voteError}
            <br />
            An error occured. Please contact us at{' '}
            <a href="mailto:support@multik.io">support@multik.io</a>
          </S.ErrorText>
        )}
      </S.VoteWeightWrap>
    );
  },
);

interface VotesInfoProps {
  votes: TransactionVote[];
}
const VotesInfo: FC<VotesInfoProps> = memo(({ votes }) => {
  const { user: appUser } = useContext(AppContext);

  return (
    <S.StatusBarLineVotes>
      {votes?.map(({ vote, user, created_at }, index) => (
        <S.StatusBarLineSubItem
          key={index}
          className="completed"
          dir={vote > 0 ? 'up' : 'down'}
        >
          <S.ConfirmationsVote>
            <S.IconConfirmationsVote />
            {Math.abs(vote)}
            <S.ConfirmationsVoteEmail>
              {user} {user === appUser?.email ? '(you)' : ''}
            </S.ConfirmationsVoteEmail>
          </S.ConfirmationsVote>
          <StatusLineDate date={created_at} />
        </S.StatusBarLineSubItem>
      ))}
    </S.StatusBarLineVotes>
  );
});

interface StatusTitleProps {
  title: string;
  comment?: string;
  time?: string;
}
const StatusTitle: FC<StatusTitleProps> = memo(({ title, comment, time }) => {
  return (
    <S.StatusBarLineRow>
      <S.StatusBarLineTitle>
        <span>{title}</span>
        {comment ? comment : null}
      </S.StatusBarLineTitle>
      {time && <StatusLineDate date={time} />}
    </S.StatusBarLineRow>
  );
});

interface StatusLineDateProps {
  date: string | Date | number;
}
const StatusLineDate: FC<StatusLineDateProps> = memo(({ date }) => (
  <Tippy
    trigger="mouseenter"
    placement="top"
    theme="transparent"
    hideOnClick={false}
    content={
      <S.StatusBarLineDateTooltip>
        {formattedDate(new Date(date), 'YYYY-MM-DD HH:mm:ss')}
      </S.StatusBarLineDateTooltip>
    }
  >
    <S.StatusBarLineDate>{dateAgo(new Date(date))}</S.StatusBarLineDate>
  </Tippy>
));

interface StatusLineProps {
  tx: Transaction;
  line: StatusBar;
}
const StatusLine: FC<StatusLineProps> = memo(({ line, tx }) => {
  const { id, isVisible, getData, lines } = line;
  const { appSettings } = useContext(AppContext);
  const { title, status, comment, time } = getData(
    tx,
    appSettings.proposal_votes_value_to_approve,
  );
  const visible = isVisible(tx);

  if (!visible) return null;

  if (id === 'confirmations' && !tx?.votes?.length) return null;

  return (
    <S.StatusBarLineItem
      className={status}
      loading={
        status === 'active' && id !== 'confirmations' && id !== 'processing'
      }
    >
      <StatusTitle title={title} comment={comment} time={time} />
      {/* @ts-ignore */}
      {id === 'confirmations' && <VotesInfo votes={tx.votes} />}
      {lines?.length && <StatusSubLines lines={lines} tx={tx} />}
    </S.StatusBarLineItem>
  );
});

interface StatusSubLinesProps {
  lines: StatusBar[];
  tx: Transaction;
}
export const StatusSubLines: FC<StatusSubLinesProps> = memo(({ lines, tx }) => {
  return (
    <S.StatusBarLineSubItems>
      {lines.map((line, index) => (
        <StatusSubLine key={index} line={line} tx={tx} />
      ))}
    </S.StatusBarLineSubItems>
  );
});

interface StatusSubLineProps {
  line: StatusBar;
  tx: Transaction;
}
export const StatusSubLine: FC<StatusSubLineProps> = memo(({ line, tx }) => {
  const { isVisible, getData } = line;
  const { title, status, comment, time } = getData(tx);
  const visible = isVisible(tx);

  if (!visible) return null;

  return (
    <S.StatusBarLineSubItem className={status}>
      <div>
        {title}
        {comment && (
          <S.StatusBarLineSubItemError>{comment}</S.StatusBarLineSubItemError>
        )}
      </div>
      {time && <StatusLineDate date={time} />}
    </S.StatusBarLineSubItem>
  );
});

interface StatusBarProps {
  tx: Transaction;
  voteUp?: any;
  voteDown?: any;
  voteError?: any;
}
export const StatusBar: FC<StatusBarProps> = memo(
  ({ tx, voteUp, voteDown, voteError }) => {
    return (
      <S.StatusBar>
        <CreatedLine
          creator={tx.created_by as string}
          createdAt={tx.created_at}
        />
        <StatusLines tx={tx} />
        {tx.status === 'QUEUED' && (
          // @ts-ignore
          <VoteButtons
            voteError={voteError}
            tx={tx}
            voteUp={voteUp}
            voteDown={voteDown}
          />
        )}
      </S.StatusBar>
    );
  },
);
