import { useContext, useEffect, useState, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import AppContext from 'shared/contexts/AppContext';
import { ProtectedArea } from 'shared/components';

import useOnClickOutside from 'use-onclickoutside';

import {
  AuthStoreInstance,
  SettingsStoreInstance,
  UserStoreInstance,
} from 'services';
import { PermissionType } from 'shared/types';

import * as S from './styled';
import { MultisigTransactionsStoreInstance } from 'features/Multisig/mobx/MultisigTransactionsStore';
import { useIsMobile } from 'shared/hooks/useIsMobile';

type Submenu = {
  submenu_title: string;
  submenu_alias: string;
  component: any | null;
};
const PAGES: {
  title: string;
  alias: string;
  icon: any;
  permission: PermissionType;
  submenu: Submenu[] | null;
}[] = [
  {
    title: 'Overview',
    alias: '/',
    icon: <S.IconOverview />,
    permission: 'accounts.view_account',
    submenu: null,
  },
  {
    title: 'Send funds',
    alias: '/transfer',
    icon: <S.IconTransfer />,
    permission: 'accounts.view_account',
    submenu: null,
  },
  {
    title: 'Transactions',
    alias: '/transactions',
    icon: <S.IconTransactions />,
    permission: 'accounts.view_account',
    submenu: null,
  },
  {
    title: 'Whitelists',
    alias: '/whitelists',
    icon: <S.IconWhitelists />,
    permission: 'accounts.view_account',
    submenu: null,
  },
  {
    title: 'Wallets',
    alias: '/multisig-wallets',
    icon: <S.IconMultisig />,
    permission: 'accounts.view_account',
    submenu: [
      {
        submenu_title: 'Assets',
        submenu_alias: 'assets',
        component: null,
      },
      {
        submenu_title: 'Transactions',
        submenu_alias: 'transactions',
        component: null,
      },
      {
        submenu_title: 'Apps',
        submenu_alias: 'apps',
        component: null,
      },
      // {
      //   submenu_title: 'Settings',
      //   submenu_alias: 'settings',
      //   component: null,
      // },
    ],
  },
];

export function Aside() {
  const location = useLocation();
  const { signOut } = AuthStoreInstance;
  const { user } = UserStoreInstance;
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isHideSidebar,
    setIsHideSidebar,
    multisigTab,
    setMultisigTab,
    theme,
  } = useContext(AppContext);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const selectRef = useRef(null);

  useOnClickOutside(selectRef, () => setIsDropdownOpen(false));

  function handleSetMultisigTab(alias: string) {
    setSearchParams('');
    localStorage.setItem('multisig_tab', alias);
    setMultisigTab(alias);
  }

  const [isMobile, _, handleAsideSize] = useIsMobile(800);

  return (
    <S.AsideWrap className={isHideSidebar ? 'hidden' : ''} isMobile={isMobile}>
      <S.Top isMobile={isMobile}>
        <S.LogoWrap
          to="/"
          end
          ismobile={isMobile ? 'true' : undefined}
          style={({ isActive }) =>
            isActive ? { pointerEvents: 'none' } : undefined
          }
          onClick={() => setIsHideSidebar(false)}
        >
          {isMobile ? <S.MobileLogo dark={theme === 'dark'} /> : <S.Logo />}
        </S.LogoWrap>
        <S.MainMenu className={isHideSidebar ? 'hidden' : ''}>
          {PAGES.map((item, index) => {
            if (item.title === 'Wallets') {
              const { appSettings } = SettingsStoreInstance;
              if (!appSettings.gnosis_enabled) return null;
            }

            return (
              <ProtectedArea every={[item.permission]} key={item?.alias}>
                <S.MenuItemWrap>
                  <S.MenuItemLink
                    to={item?.alias}
                    ismobile={isMobile ? 'true' : undefined}
                    className={location.pathname === item.alias ? 'active' : ''}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    {item.icon ? item.icon : null}
                    <span>{item.title}</span>
                    {item.submenu?.length ? (
                      <S.ArrowIcon
                        className="dropdownarrow"
                        ismobile={isMobile ? 'true' : undefined}
                      />
                    ) : null}
                  </S.MenuItemLink>
                  {location.pathname === item?.alias && item.submenu?.length ? (
                    <S.DropdownMenu
                      ref={selectRef}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.2 } }}
                      exit={{ opacity: 0, transition: { duration: 0.2 } }}
                      isshown={isDropdownOpen ? 'true' : undefined}
                      ismobile={isMobile ? 'true' : undefined}
                    >
                      {item.submenu.map((submenu) => {
                        return (
                          <S.DropdownMenuItem
                            activeMenu={multisigTab === submenu.submenu_alias}
                            key={submenu.submenu_alias}
                            isMobile={isMobile}
                          >
                            <span
                              onClick={() =>
                                handleSetMultisigTab(submenu.submenu_alias)
                              }
                            >
                              {submenu.submenu_title}
                            </span>
                          </S.DropdownMenuItem>
                        );
                      })}
                    </S.DropdownMenu>
                  ) : null}
                </S.MenuItemWrap>
              </ProtectedArea>
            );
          })}
        </S.MainMenu>
      </S.Top>
      <S.UserMenu isMobile={isMobile} className={isHideSidebar ? 'hidden' : ''}>
        <S.MenuItemWrap>
          <S.MenuItemLink
            to="/helpdesk"
            ismobile={isMobile ? 'true' : undefined}
            className={location.pathname === '/helpdesk' ? 'active' : ''}
          >
            <S.IconHelp />
            <S.UserFullName>Helpdesk</S.UserFullName>
          </S.MenuItemLink>
        </S.MenuItemWrap>
        <S.MenuItemWrap>
          <S.MenuItemLink
            to="/profile"
            ismobile={isMobile ? 'true' : undefined}
            className={location.pathname === '/profile' ? 'active' : ''}
          >
            <S.IconUser />
            <S.UserFullName>
              {user?.first_name} {user?.last_name}
            </S.UserFullName>
          </S.MenuItemLink>
        </S.MenuItemWrap>
        <S.MenuItemWrap>
          <S.LogoutButton isMobile={isMobile} onClick={() => signOut()}>
            <S.IconLogout />
            <span>Log out</span>
          </S.LogoutButton>
        </S.MenuItemWrap>

        <S.MenuItemWrap>
          <S.AsideButton onClick={handleAsideSize} isMobile={isMobile}>
            <S.ArrowIconAside />
          </S.AsideButton>
        </S.MenuItemWrap>
      </S.UserMenu>
    </S.AsideWrap>
  );
}
