import {
  ChainInfo,
  Erc721Transfer,
  FEATURES,
  GasPriceFixed,
  GasPriceOracle,
  GAS_PRICE_TYPE,
  RpcUri,
  RPC_AUTHENTICATION,
  TransactionStatus,
  TransactionTokenType,
  Transfer,
} from '@gnosis.pm/safe-react-gateway-sdk';
import semverSatisfies from 'semver/functions/satisfies';
import { BigNumber } from 'bignumber.js';
import { INFURA_TOKEN, LATEST_SAFE_VERSION } from 'features/Multisig/constants';
import {
  isAddress,
  isHexStrict,
  checkAddressChecksum,
  toChecksumAddress,
  hexToBytes,
} from 'web3-utils';
import { Log } from 'web3-core';
// import { sameString } from 'src/utils/strings'
import { bufferToHex, ecrecover, pubToAddress } from 'ethereumjs-util';
// @ts-ignore
import abiDecoder from 'abi-decoder';
import { getProxyFactoryDeployment } from '@gnosis.pm/safe-deployments';
import { getRecommendedNonce } from './mobx/logic/ethTransactions';
import { getGnosisSafeInstanceAt } from './mobx/logic/safeContracts';
import { memoize } from 'lodash';

const lt1kFormatter = new Intl.NumberFormat([], { maximumFractionDigits: 5 });
const lt10kFormatter = new Intl.NumberFormat([], { maximumFractionDigits: 4 });
const lt100kFormatter = new Intl.NumberFormat([], { maximumFractionDigits: 3 });
const lt1mFormatter = new Intl.NumberFormat([], { maximumFractionDigits: 2 });
const lt10mFormatter = new Intl.NumberFormat([], { maximumFractionDigits: 1 });
const lt100mFormatter = new Intl.NumberFormat([], { maximumFractionDigits: 0 });
// same format for billions and trillions
const lt1000tFormatter = new Intl.NumberFormat([], {
  maximumFractionDigits: 3,
  notation: 'compact',
} as any);

export const fromTokenUnit = (
  amount: number | string,
  decimals: string | number,
): string => new BigNumber(amount).times(`1e-${decimals}`).toFixed();

export const formatAmount = (number: string): string => {
  let numberFloat: number | string = parseFloat(number);

  if (numberFloat === 0) {
    numberFloat = '0';
  } else if (numberFloat < 0.001) {
    numberFloat = '< 0.001';
  } else if (numberFloat < 1000) {
    numberFloat = lt1kFormatter.format(numberFloat);
  } else if (numberFloat < 10000) {
    numberFloat = lt10kFormatter.format(numberFloat);
  } else if (numberFloat < 100000) {
    numberFloat = lt100kFormatter.format(numberFloat);
  } else if (numberFloat < 1000000) {
    numberFloat = lt1mFormatter.format(numberFloat);
  } else if (numberFloat < 10000000) {
    numberFloat = lt10mFormatter.format(numberFloat);
  } else if (numberFloat < 100000000) {
    numberFloat = lt100mFormatter.format(numberFloat);
  } else if (numberFloat < 10 ** 15) {
    numberFloat = lt1000tFormatter.format(numberFloat);
  } else {
    numberFloat = '> 1000T';
  }

  return numberFloat;
};

/**
 * Util to compare two strings, comparison is case insensitive
 * @param str1
 * @param str2
 * @returns {boolean}
 */
export const sameString = (
  str1: string | undefined,
  str2: string | undefined,
): boolean => {
  if (!str1 || !str2) {
    return false;
  }

  return str1.toLowerCase() === str2.toLowerCase();
};

export const _setChain = (chain: ChainInfo) => {
  localStorage.setItem('CHAIN', JSON.stringify(chain));
};

export const _getChain = () => {
  const storageChain = localStorage.getItem('CHAIN');
  return JSON.parse(
    storageChain && storageChain !== 'undefined' ? storageChain : '{}',
  );
};

export const _getChainId = () => {
  const chain = JSON.parse(localStorage.getItem('CHAIN') || '{}');
  return chain.chainId;
};

export const isValidAddress = (address?: string): boolean => {
  if (address) {
    // `isAddress` do not require the string to start with `0x`
    // `isHexStrict` ensures the address to start with `0x` aside from being a valid hex string
    return isHexStrict(address) && isAddress(address);
  }

  return false;
};

export const checksumAddress = (address: string): string => {
  if (!isValidAddress(address)) {
    return '';
  }

  try {
    return toChecksumAddress(address);
  } catch (err) {
    console.log(err);
    return '';
  }
};

export const isChecksumAddress = (address?: string): boolean => {
  if (address) {
    return checkAddressChecksum(address);
  }

  return false;
};

export const isValidCryptoDomainName = (name: string): boolean =>
  /^([\w-]+\.)+(crypto)$/.test(name);

const formatRpcServiceUrl = (
  { authentication, value }: RpcUri,
  TOKEN: string,
): string => {
  const needsToken = authentication === RPC_AUTHENTICATION.API_KEY_PATH;
  return needsToken ? `${value}${TOKEN}` : value;
};

export const getRpcServiceUrl = (rpcUri = _getChain().rpcUri): string => {
  return formatRpcServiceUrl(rpcUri, INFURA_TOKEN);
};

const FEATURES_BY_VERSION: Record<string, string> = {
  [FEATURES.SAFE_TX_GAS_OPTIONAL]: '>=1.3.0',
};

const isEnabledByVersion = (feature: FEATURES, version: string): boolean => {
  if (!(feature in FEATURES_BY_VERSION)) return true;
  return semverSatisfies(version, FEATURES_BY_VERSION[feature]);
};

/**
 * Get a combined list of features enabled per chain and per version
 */
export const enabledFeatures = (version?: string): FEATURES[] => {
  const chainFeatures = _getChain().features;
  if (!version) return chainFeatures;
  return chainFeatures.filter((feat: FEATURES) =>
    isEnabledByVersion(feat, version),
  );
};

export const hasFeature = (name: FEATURES, version?: string): boolean => {
  return enabledFeatures(version).includes(name);
};

export const getGasPriceOracles = (): Extract<
  ChainInfo['gasPrice'][number],
  GasPriceOracle
>[] => {
  const isOracleType = (
    gasPrice: ChainInfo['gasPrice'][number],
  ): gasPrice is GasPriceOracle => {
    return gasPrice.type === GAS_PRICE_TYPE.ORACLE;
  };
  return _getChain().gasPrice.filter(isOracleType);
};

export const getFixedGasPrice = (): Extract<
  ChainInfo['gasPrice'][number],
  GasPriceFixed
> => {
  const isFixed = (
    gasPrice: ChainInfo['gasPrice'][number],
  ): gasPrice is GasPriceFixed => {
    return gasPrice.type === GAS_PRICE_TYPE.FIXED;
  };
  return _getChain().gasPrice.filter(isFixed)[0];
};

abiDecoder.addABI(
  getProxyFactoryDeployment({
    version: LATEST_SAFE_VERSION,
  })?.abi,
);

export const getNewSafeAddressFromLogs = (logs: Log[]): string => {
  // We find the ProxyCreation event in the logs
  const proxyCreationEvent = abiDecoder
    .decodeLogs(logs)
    .find(({ name }: any) => name === 'ProxyCreation');

  // We extract the proxy creation information from the event parameters
  const proxyInformation = proxyCreationEvent?.events?.find(
    ({ name }: any) => name === 'proxy',
  );

  return checksumAddress(proxyInformation?.value || '');
};

export const getByteLength = (data: string | string[]): number => {
  try {
    if (!Array.isArray(data)) {
      data = data.split(',');
    }
    // Return the sum of the byte sizes of each hex string
    return data.reduce((result, hex) => {
      const bytes = hexToBytes(hex);
      return result + bytes.length;
    }, 0);
  } catch (err) {
    return 0;
  }
};

export const isTxQueued = (status: string): boolean => {
  return [
    'PENDING',
    TransactionStatus.AWAITING_CONFIRMATIONS,
    TransactionStatus.AWAITING_EXECUTION,
    'WILL_BE_REPLACED',
  ].includes(status);
};

interface AmountData {
  decimals?: number | string;
  symbol?: string;
  value: number | string;
}

const getAmountWithSymbol = (
  { decimals = 0, symbol = 'n/a', value }: AmountData,
  formatted = false,
): string => {
  const nonFormattedValue = new BigNumber(value)
    .times(`1e-${decimals}`)
    .toFixed();
  const finalValue = formatted
    ? formatAmount(nonFormattedValue).toString()
    : nonFormattedValue;
  const txAmount = finalValue === 'NaN' ? 'n/a' : finalValue;

  return `${txAmount} ${symbol}`;
};

export const getTokenIdLabel = ({ tokenId }: Erc721Transfer): string => {
  return tokenId ? `(#${tokenId})` : '';
};

export const getTxAmount = (txInfo?: Transfer, formatted = true): string => {
  if (!txInfo || txInfo.type !== 'Transfer') {
    return 'n/a';
  }

  switch (txInfo.transferInfo.type) {
    case TransactionTokenType.ERC20:
      return getAmountWithSymbol(
        {
          decimals: `${txInfo.transferInfo.decimals ?? 0}`,
          symbol: `${txInfo.transferInfo.tokenSymbol ?? 'n/a'}`,
          value: txInfo.transferInfo.value,
        },
        formatted,
      );
    case TransactionTokenType.ERC721:
      // simple workaround to avoid displaying unexpected values for incoming NFT transfer
      return `1 ${txInfo.transferInfo.tokenSymbol} ${getTokenIdLabel(
        txInfo.transferInfo,
      )}`;
    case TransactionTokenType.NATIVE_COIN: {
      const chain = _getChain();
      const nativeCurrency = chain.nativeCurrency;
      return getAmountWithSymbol(
        {
          decimals: nativeCurrency.decimals,
          symbol: nativeCurrency.symbol,
          value: txInfo.transferInfo.value,
        },
        formatted,
      );
    }
    default:
      return 'n/a';
  }
};

export const isTxHashSignedWithPrefix = (
  txHash: string,
  signature: string,
  ownerAddress: string,
): boolean => {
  let hasPrefix;
  try {
    const rsvSig = {
      r: Buffer.from(signature.slice(2, 66), 'hex'),
      s: Buffer.from(signature.slice(66, 130), 'hex'),
      v: parseInt(signature.slice(130, 132), 16),
    };
    const recoveredData = ecrecover(
      Buffer.from(txHash.slice(2), 'hex'),
      rsvSig.v,
      rsvSig.r,
      rsvSig.s,
    );
    const recoveredAddress = bufferToHex(pubToAddress(recoveredData));
    hasPrefix = !sameString(recoveredAddress, ownerAddress);
  } catch (e) {
    hasPrefix = true;
  }
  return hasPrefix;
};

type AdjustVOverload = {
  (signingMethod: 'eth_signTypedData', signature: string): string;
  (
    signingMethod: 'eth_sign',
    signature: string,
    safeTxHash: string,
    sender: string,
  ): string;
};

export const adjustV: AdjustVOverload = (
  signingMethod: 'eth_sign' | 'eth_signTypedData',
  signature: string,
  safeTxHash?: string,
  sender?: string,
): string => {
  const MIN_VALID_V_VALUE = 27;
  let sigV = parseInt(signature.slice(-2), 16);

  if (signingMethod === 'eth_sign') {
    /*
      Usually returned V (last 1 byte) is 27 or 28 (valid ethereum value)
      Metamask with ledger returns v = 01, this is not valid for ethereum
      In case V = 0 or 1 we add it to 27 or 28
      Adding 4 is required if signed message was prefixed with "\x19Ethereum Signed Message:\n32"
      Some wallets do that, some wallets don't, V > 30 is used by contracts to differentiate between prefixed and non-prefixed messages
      https://github.com/gnosis/safe-contracts/blob/main/contracts/GnosisSafe.sol#L292
    */
    if (sigV < MIN_VALID_V_VALUE) {
      sigV += MIN_VALID_V_VALUE;
    }
    const adjusted = signature.slice(0, -2) + sigV.toString(16);
    const signatureHasPrefix = isTxHashSignedWithPrefix(
      safeTxHash as string,
      adjusted,
      sender as string,
    );
    if (signatureHasPrefix) {
      sigV += 4;
    }
  }

  if (signingMethod === 'eth_signTypedData') {
    // Metamask with ledger returns V=0/1 here too, we need to adjust it to be ethereum's valid value (27 or 28)
    if (sigV < MIN_VALID_V_VALUE) {
      sigV += MIN_VALID_V_VALUE;
    }
  }

  return signature.slice(0, -2) + sigV.toString(16);
};

export const getNonce = async (
  safeAddress: string,
  safeVersion: string,
): Promise<string> => {
  let nextNonce: string;
  try {
    nextNonce = (await getRecommendedNonce(safeAddress)).toString();
  } catch (e) {
    console.error('Error while getting nonce', e);
    const safeInstance = getGnosisSafeInstanceAt(safeAddress, safeVersion);
    nextNonce = await safeInstance.methods.nonce().call();
  }
  return nextNonce;
};

// This providers have direct relation with name assigned in bnc-onboard configuration
export enum WALLET_PROVIDER {
  METAMASK = 'METAMASK',
  TALLYHO = 'TALLYHO',
  TORUS = 'TORUS',
  PORTIS = 'PORTIS',
  FORTMATIC = 'FORTMATIC',
  SQUARELINK = 'SQUARELINK',
  WALLETCONNECT = 'WALLETCONNECT',
  TRUST = 'TRUST',
  OPERA = 'OPERA',
  // This is the provider for WALLET_LINK configuration on bnc-onboard
  COINBASE_WALLET = 'COINBASE WALLET',
  AUTHEREUM = 'AUTHEREUM',
  LEDGER = 'LEDGER',
  TREZOR = 'TREZOR',
  LATTICE = 'LATTICE',
  KEYSTONE = 'KEYSTONE',
  // Safe name as PAIRING_MODULE_NAME
  SAFE_MOBILE = 'SAFE MOBILE',
}

export const isHardwareWallet = (wallet: any): boolean => {
  const isSupportedHardwareWallet = [
    WALLET_PROVIDER.LEDGER,
    WALLET_PROVIDER.TREZOR,
  ].includes(wallet?.name?.toUpperCase() as WALLET_PROVIDER);
  const isHardwareWalletType = wallet?.type === 'hardware';

  return isSupportedHardwareWallet || isHardwareWalletType;
};

// These follow the guideline of "How to format amounts"
// https://github.com/5afe/safe/wiki/How-to-format-amounts

const LOWER_LIMIT = 0.00001;
const COMPACT_LIMIT = 99_999_999.5;
const UPPER_LIMIT = 999 * 10 ** 12;

/**
 * Formatter that restricts the upper and lower limit of numbers that can be formatted
 * @param number Number to format
 * @param formatter Function to format number
 * @param minimum Minimum number to format
 */
const format = (
  number: string | number,
  formatter: (float: number) => string,
  minimum = LOWER_LIMIT,
) => {
  const float = Number(number);

  if (float === 0) {
    return formatter(float);
  }

  if (Math.abs(float) < minimum) {
    return `< ${formatter(minimum * Math.sign(float))}`;
  }

  if (float < UPPER_LIMIT) {
    return formatter(float);
  }

  return `> ${formatter(UPPER_LIMIT)}`;
};

// Universal amount formatting options

const getNumberFormatNotation = (
  number: string | number,
): Intl.NumberFormatOptions['notation'] => {
  return Number(number) >= COMPACT_LIMIT ? 'compact' : undefined;
};

const getNumberFormatSignDisplay = (
  number: string | number,
): Intl.NumberFormatOptions['signDisplay'] => {
  const shouldDisplaySign =
    typeof number === 'string'
      ? number.trim().startsWith('+')
      : Number(number) < 0;
  return shouldDisplaySign ? 'exceptZero' : undefined;
};

// Amount formatting options

const getAmountFormatterMaxFractionDigits = (
  number: string | number,
): Intl.NumberFormatOptions['maximumFractionDigits'] => {
  const float = Number(number);

  if (float < 1_000) {
    return 5;
  }

  if (float < 10_000) {
    return 4;
  }

  if (float < 100_000) {
    return 3;
  }

  if (float < 1_000_000) {
    return 2;
  }

  if (float < 10_000_000) {
    return 1;
  }

  if (float < COMPACT_LIMIT) {
    return 0;
  }

  // Represents numbers like 767.343M
  if (float < UPPER_LIMIT) {
    return 3;
  }

  return 0;
};

const getAmountFormatterOptions = (
  number: string | number,
): Intl.NumberFormatOptions => {
  return {
    maximumFractionDigits: getAmountFormatterMaxFractionDigits(number),
    notation: getNumberFormatNotation(number),
    signDisplay: getNumberFormatSignDisplay(number),
  };
};
export const formatAmountNew = (
  number: string | number,
  precision?: number,
): string => {
  const options = getAmountFormatterOptions(number);
  if (precision !== undefined) {
    options.maximumFractionDigits = precision;
  }
  const formatter = new Intl.NumberFormat(undefined, options);

  return format(number, formatter.format);
};

/**
 * Returns a formatted number with a defined precision not adhering to our style guide compact notation
 * @param number Number to format
 * @param precision Fraction digits to show
 */
export const formatAmountPrecise = (
  number: string | number,
  precision: number,
): string => {
  const float = Number(number);

  const formatter = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: precision,
  });

  return formatter.format(float);
};

// Fiat formatting

const getMinimumCurrencyDenominator = memoize((currency: string): number => {
  const BASE_VALUE = 1;

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
  });

  const fraction = formatter
    .formatToParts(BASE_VALUE)
    .find(({ type }) => type === 'fraction');

  // Currencies may not have decimals, i.e. JPY
  return fraction ? Number(`0.${'1'.padStart(fraction.value.length, '0')}`) : 1;
});

const getCurrencyFormatterMaxFractionDigits = (
  number: string | number,
  currency: string,
): Intl.NumberFormatOptions['maximumFractionDigits'] => {
  const float = Number(number);

  if (float < 1_000_000) {
    const [, decimals] = getMinimumCurrencyDenominator(currency)
      .toString()
      .split('.');
    return decimals?.length ?? 0;
  }

  // Represents numbers like 767.343M
  if (float < UPPER_LIMIT) {
    return 3;
  }

  return 0;
};

const getCurrencyFormatterOptions = (
  number: string | number,
  currency: string,
): Intl.NumberFormatOptions => {
  return {
    maximumFractionDigits: getCurrencyFormatterMaxFractionDigits(
      number,
      currency,
    ),
    notation: getNumberFormatNotation(number),
    signDisplay: getNumberFormatSignDisplay(number),
    style: 'currency',
    currency,
    currencyDisplay: 'code',
  };
};

/**
 * Currency formatter that appends the currency code
 * @param number Number to format
 * @param currency ISO 4217 currency code
 */
export const formatCurrency = (
  number: string | number,
  currency: string,
): string => {
  // Note: we will be able to achieve the following once the `roundingMode` option is supported
  // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters

  const minimum = getMinimumCurrencyDenominator(currency);

  const currencyFormatter = (float: number): string => {
    const options = getCurrencyFormatterOptions(number, currency);
    const formatter = new Intl.NumberFormat(undefined, options);

    const parts = formatter.formatToParts(float); // Returns an array of objects with `type` and `value` properties

    const fraction = parts.find(({ type }) => type === 'fraction');

    const amount = parts
      .filter(({ type }) => type !== 'currency' && type !== 'literal') // Remove currency code and whitespace
      .map((part) => {
        if (float >= 0) {
          return part;
        }

        if (fraction && part.type === 'fraction') {
          return { ...part, value: '1'.padStart(fraction.value.length, '0') };
        }

        if (!fraction && part.type === 'integer') {
          return { ...part, value: minimum.toString() };
        }

        return part;
      })
      .reduce((acc, { value }) => acc + value, '')
      .trim();

    return `${amount} ${currency.toUpperCase()}`;
  };

  return format(number, currencyFormatter, minimum);
};
