export interface VoteUpTransactionParameters {
  tfa?: string;
  otp?: string;
  id: number;
}

export interface VoteDownTransactionParameters {
  otp?: string;
  id: number;
}

export interface ApiListResponse<T> {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
}

export const TransactionDisplayStatus = {
  AWAITING_CONFIRMATIONS: 'AWAITING CONFIRMATIONS',
  AWAITING_APPROVAL: 'AWAITING APPROVAL',
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  CLOSED: 'CLOSED',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
};
export type TransactionDisplayStatusType =
  (typeof TransactionDisplayStatus)[keyof typeof TransactionDisplayStatus];

export const TransactionCurrencyAltCrypto = {
  AAVE: 'AAVE',
  ADA: 'ADA',
  ATOM: 'ATOM',
  AVAX: 'AVAX',
  AXS: 'AXS',
  BAT: 'BAT',
  COMP: 'COMP',
  CRV: 'CRV',
  DAI: 'DAI',
  DCR: 'DCR',
  DOGE: 'DOGE',
  DYDX: 'DYDX',
  FET: 'FET',
  FIL: 'FIL',
  FTM: 'FTM',
  FTT: 'FTT',
  GRT: 'GRT',
  ICP: 'ICP',
  LEO: 'LEO',
  LINK: 'LINK',
  LTC: 'LTC',
  MANA: 'MANA',
  MATIC: 'MATIC',
  MKR: 'MKR',
  OMG: 'OMG',
  ONT: 'ONT',
  QTUM: 'QTUM',
  REP: 'REP',
  REN: 'REN',
  SAND: 'SAND',
  SNX: 'SNX',
  SHIB: 'SHIB',
  SUSHI: 'SUSHI',
  STORJ: 'STORJ',
  USDC: 'USDC',
  UNI: 'UNI',
  UST: 'UST',
  XTZ: 'XTZ',
  ZRX: 'ZRX',
  CVX: 'CVX',
  ZEC: 'ZEC',
};
export type TransactionCurrencyAltCryptoType =
  (typeof TransactionCurrencyAltCrypto)[keyof typeof TransactionCurrencyAltCrypto];

export const TransactionCurrencyCrypto = {
  BTC: 'BTC',
  ETH: 'ETH',
  NATIVE_COIN: 'NATIVE_COIN',
  EURS: 'EURS',
  USDT: 'USDT',
};
export type TransactionCurrencyCryptoType =
  (typeof TransactionCurrencyCrypto)[keyof typeof TransactionCurrencyCrypto];

export const TransactionCurrencyFiat = {
  EUR: 'EUR',
  USD: 'USD',
};
export type TransactionCurrencyFiatType =
  (typeof TransactionCurrencyFiat)[keyof typeof TransactionCurrencyFiat];

export const TransactionCurrency = {
  ...TransactionCurrencyAltCrypto,
  ...TransactionCurrencyCrypto,
  ...TransactionCurrencyFiat,
};
export type TransactionCurrencyType =
  (typeof TransactionCurrency)[keyof typeof TransactionCurrency];

export const TransactionExchange = {
  WALLETS: 'WALLETS',
  BINANCE: 'BINANCE',
  BITSTAMP: 'BITSTAMP',
  BITFINEX: 'BITFINEX',
  DERIBIT: 'DERIBIT',
  GLOBITEX: 'GLOBITEX',
  HITBTC: 'HITBTC',
  GOZO: 'GOZO',
  KRAKEN: 'KRAKEN',
  LMAX: 'LMAX',
  OKCOIN: 'OKCOIN',
  FTX: 'FTX',
  DYDX: 'DYDX',
};
export type TransactionExchangeType =
  (typeof TransactionExchange)[keyof typeof TransactionExchange];

export const TransactionDepositStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};
export type TransactionDepositStatusType =
  (typeof TransactionDepositStatus)[keyof typeof TransactionDepositStatus];

export const TransactionWithdrawalStatus = {
  ...TransactionDepositStatus,
  INITIATED: 'INITIATED',
  UNCONFIRMED: 'UNCONFIRMED',
  CANCELED: 'CANCELED',
  REJECTED: 'REJECTED',
};
export type TransactionWithdrawalStatusType =
  (typeof TransactionWithdrawalStatus)[keyof typeof TransactionWithdrawalStatus];

export const TransactionStatus = {
  ...TransactionWithdrawalStatus,
  QUEUED: 'QUEUED',
  CLOSED: 'CLOSED',
  SENT: 'SENT',
  RECEIVED: 'RECEIVED',
  PROCESSING: 'PROCESSING',
};
export type TransactionStatusType =
  (typeof TransactionStatus)[keyof typeof TransactionStatus];

export interface CreateTransactionParameters {
  wallet_from: number;
  deposit_address: string;
  amount: string;
  description?: string;
  tfa?: string;
}

export interface GetTransactionsParameters {
  search?: string;
  ordering?: string;
  created_at_before?: string;
  created_at_after?: string;
  updated_at_before?: string;
  updated_at_after?: string;
  currency?: TransactionCurrencyType;
  exchange_from?: TransactionExchangeType[];
  exchange_to?: TransactionExchangeType[];
  account_from?: number[];
  account_to?: number[];
  amount_min?: number;
  amount_max?: number;
  status?: TransactionStatusType[];
  limit: number;
  offset: number;
}

export interface TransactionAccount {
  id: number;
  name: string;
  exchange: TransactionExchangeType;
}

export interface TransactionVote {
  user: string;
  vote: number;
  created_at: string;
}

export interface Wallet {
  id: number;
  type: string;
}

export interface Transaction {
  id: number;
  account_from: TransactionAccount | null;
  account_to: TransactionAccount;
  currency: TransactionCurrencyType;
  deposit_address: string;
  amount: string;
  sender_address: string;
  status: string;
  updated_at: string;
  created_at: string;
  fee: string | null;
  tx_id: string;
  external_id: string | null;
  withdrawal_status: TransactionWithdrawalStatusType | null;
  withdrawal_updated_at: string;
  deposit_status: TransactionDepositStatusType;
  deposit_updated_at: string;
  comment: string;
  description: string;
  created_by: string | null;
  resolved_by: string;
  sum_of_votes: number;
  votes: TransactionVote[];
  is_internal_transfer: boolean;
  wallet_from: Wallet;
  wallet_to: Wallet;
  network: string;
}
