import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { ReactComponent as SvgX } from 'shared/assets/icons/icon-modal-close.svg';
import { ReactComponent as SvgDown } from 'shared/assets/icons/icon-direction-down.svg';
import { ReactComponent as SvgArrowRight } from 'shared/assets/icons/icon-arrow-right.svg';
import { ReactComponent as SvgClose } from 'shared/assets/icons/icon-x.svg';

export const OtpProposalDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OtpAmount = styled.div`
  font-family: Roboto Mono;
  color: var(--object-primary);
  font-size: 24px;
  line-height: 31.65px;
  margin-bottom: 10px;
`;

export const OtpDirectionIcon = styled(SvgDown)`
  margin: 10px 0;
`;

export const OtpAccount = styled.div`
  font-size: 10px;
  line-height: 13.2px;
  display: flex;
  flex-direction: column;
`;

export const OtpAccountName = styled.div<{ unavailable: boolean }>`
  color: ${({ unavailable }) =>
    unavailable ? 'var(--object-secondary)' : 'var(--object-primary)'};
  margin-bottom: 5px;
`;

export const SendButton = styled.button<{ disabled?: boolean }>`
  padding: 10px 10px 10px 20px;
  border-radius: 40px;
  height: 40px;
  margin: 0 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark-bg);
  background: var(--accent-primary);
  border: none;
  cursor: pointer;
  user-select: none;
  align-self: end;

  span {
    line-height: 20px;
    font-size: 12px;
    text-transform: uppercase;
  }

  ${(props) =>
    props.disabled &&
    css`
      background: var(--placeholder);
      pointer-events: none;
    `};
`;

export const OtpAccountExchange = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: var(--object-secondary);
`;
export const OtpAccountExchangeIcon = styled.div`
  width: 10px;
  height: 10px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  margin-right: 5px;
`;

export const CodeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const IconSend = styled(SvgArrowRight)`
  width: 15px;
  height: 20px;
  margin: 0 0 0 5px;
  padding: 4px 0;
  stroke-width: 2px;
  stroke: white;
`;

export const OtpReset = styled.p`
  font-size: 9px;
  text-transform: uppercase;
  color: var(--accent-primary);
  font-weight: 700;
  line-height: 11px;
  margin: 0;
  cursor: pointer;
`;

export const OtpText = styled.p`
  font-size: 9px;
  text-transform: uppercase;
  color: var(--object-secondary);
  font-weight: 700;
  line-height: 11px;
  margin: 0;
`;

export const OtpWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 290px;
`;

export const OtpContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const OtpCodeWrap = styled.div<{
  noMargin?: boolean;
}>`
  width: 100%;
  margin: 0 0 20px 0;

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0 !important;
    `};

  .error {
    div > input {
      border: 1px solid var(--error) !important;
    }
  }

  .otp {
    width: 100% !important;

    div {
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }

    input {
      font-size: 24px;
      width: 40px !important;
      height: 40px !important;
      line-height: 40px;
      text-align: center;
      border: solid 1px var(--border) !important;
      border-radius: 5px;
      -webkit-appearance: none;
      font-family: Rubik, sans-serif !important;
      overflow: hidden;
    }
  }
`;

export const ModalText = styled.div`
  font-size: 12px;
`;

export const ModalError = styled.div<{
  noMargin?: boolean;
}>`
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 20px;
  margin-top: -10px;
  color: var(--error);
  width: 290px;

  ${(props) =>
    props.noMargin &&
    css`
      margin-bottom: 0 !important;
      margin-top: 10px !important;
    `};
`;

export const ModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;

  background: rgba(35, 59, 88, 0.5);

  overflow: overlay;
`;

export const ModalPanel = styled(motion.div)`
  background: var(--base);
  position: relative;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  min-width: 331px;
  display: flex;
  flex-direction: column;
`;

export const AdvancedWrap = styled(motion.div)`
  max-height: 80vh;
`;

export const ModalHeader = styled.div<{
  isExist?: boolean;
}>`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 0px 0px 20px;

  ${(props) =>
    props.isExist &&
    css`
      margin: 0 !important;
    `};
`;

export const CloseButton = styled.button`
  border: none;
  appearance: none;
  background: none;
  cursor: pointer;
  z-index: 115;
  position: absolute;
  right: 20px;
`;

export const CloseIcon = styled(SvgX)`
  width: 20px;
  height: 20px;
  stroke: var(--base);
`;

export const IconClose = styled(SvgClose)`
  width: 40px;
  height: 40px;
  stroke: var(--base);

  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 999;
  opacity: 0.5;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
`;

export const ModalTitle = styled.h3`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  line-height: 21px;
  font-weight: 300;
  color: var(--object-secondary);

  span {
    font-weight: 700;
    color: var(--object-primary);
  }
`;

export const ModalDiv = styled.div`
  height: 1px;
  background-color: var(--border);
  margin: 20px 0;
`;

export const ThresholdViolationInfoWrapper = styled.div`
  margin-top: 20px;
  max-width: 291px;
`;

export const ConfirmButton = styled.button`
  float: right;
  padding: 15px;
  background: var(--accent-primary);
  border: none;
  border-radius: 100px;
  cursor: pointer;
  color: var(--dark-bg);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
  min-width: 105px;
  text-align: center;
  display: flex;
  align-items: center;
  height: 40px;
  align-self: flex-end;

  &:last-child {
    margin-right: 0;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      background-color: var(--object-secondary);
      color: white;
      cursor: default;
    `};
`;

export const IconArrow = styled(SvgArrowRight)`
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 0 0 10px;
  stroke: white;
  stroke-width: 2px;
  transition: all 0.3s;
`;
