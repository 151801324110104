import { useEffect, useState, useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';

import 'shared/theme/theme.css';
import 'react-toastify/dist/ReactToastify.css';

import { GlobalStyles } from 'shared/theme';

import AppContext, { ModalProps } from 'shared/contexts/AppContext';
import { Notification } from 'shared/components/Notification';

import {
  OverviewPage,
  WhitelistsPage,
  TransferPage,
  MultisigPage,
  TransactionsPage,
  ProfilePage,
  HelpdeskPage,
} from '../pages';

import { ProtectedRoute, Login, Aside } from 'shared/components';

import {
  AccountsStoreInstance,
  AppStoreInstance,
  AuthStoreInstance,
  CurrenciesStoreInstance,
  SettingsStoreInstance,
  UserStoreInstance,
} from 'services';

import * as S from './styled';
import { Provider } from 'web3-react/dist/manager';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Modal } from 'shared/components/Modal/Modal';
import { AnimatePresence } from 'framer-motion';
import { UnsetOtp } from 'shared/components/OtpModal/UnsetOtp';
import { PermissionType, WalletType } from 'shared/types';
import { TabType } from 'features/Transactions/types';
import {
  TransferContext,
  TransferProvider,
} from 'features/Transfer/context/TransferContext';
import { AccountProvider } from 'features/Transfer/context/AccountContext';

const queryClient = new QueryClient();

export const getLibrary = (provider: Provider) => new Web3(provider);

export const getInitialTab = (permissions: PermissionType[] = []) =>
  permissions.includes('transactions.view_transaction')
    ? 'transactions'
    : 'proposals';

function RouterWrap(props: any) {
  const { contentRef, setContentRef } = props;
  const { pathname } = useLocation();
  const [pageClass, setPageClass] = useState(pathname.slice(1));

  const { isAsideMobileType } = useContext(AppContext);

  useEffect(() => {
    setPageClass(pathname.slice(1));
  }, [pathname]);

  return (
    <>
      <S.Container>
        <Aside />
        <S.Content
          /* @ts-ignore */
          ref={(ref) => setContentRef(ref)}
          id="content"
          /* @ts-ignore */
          className={pageClass}
          isAsideMobileType={isAsideMobileType}
          pathname={pathname}
          noRightPadding={
            pathname === '/transfer' || pathname === '/transactions'
          }
        >
          <Routes>
            <Route
              index
              element={<ProtectedRoute component={OverviewPage} />}
            />
            <Route
              path="/transactions"
              element={<ProtectedRoute component={TransactionsPage} />}
            />
            <Route
              path="/transfer"
              element={<ProtectedRoute component={TransferPage} />}
            />
            <Route
              path="/whitelists"
              element={<ProtectedRoute component={WhitelistsPage} />}
            />
            <Route
              path="/multisig-wallets"
              element={<ProtectedRoute component={MultisigPage} />}
            />
            <Route
              path="/profile"
              element={<ProtectedRoute component={ProfilePage} />}
            />
            <Route
              path="/helpdesk"
              element={<ProtectedRoute component={HelpdeskPage} />}
            />
          </Routes>
        </S.Content>
        <Notification />
      </S.Container>
      <AnimatePresence>
        <Modal />
      </AnimatePresence>
    </>
  );
}

function App() {
  const [currentAccNode, setCurrentAccNode] = useState(null);
  const [contentRef, setContentRef] = useState(null);
  const [modal, openModal] = useState<ModalProps | null>(null);
  const { initApplication, refetchData, stopSync } = AppStoreInstance;
  const { appToken, signOut } = AuthStoreInstance;
  const { appSettings } = SettingsStoreInstance;
  const { user, fetchUserInfo } = UserStoreInstance;
  const [transactionsTab, setTransactionsTab] = useState<TabType>(
    getInitialTab(user?.permissions),
  );
  useEffect(() => {
    setTransactionsTab(getInitialTab(user?.permissions));
  }, [user]);
  const { accounts, exchanges, getAccountById, isAccountsLoading } =
    AccountsStoreInstance;
  const { rates, getCurrencyValue, getCurrencyPrecision } =
    CurrenciesStoreInstance;

  const [isConfirming, setIsConfirming] = useState(false);
  const [isEnteringOtp, setIsEnteringOtp] = useState(false);
  const [isHideSidebar, setIsHideSidebar] = useState(false);
  const [isAsideMobileType, setAsideMobileType] = useState(false);
  const [step, setStep] = useState<number>(1);
  const [clientId, setClientId] = useState(appSettings.google_auth_key);
  const [multisigTab, setMultisigTab] = useState<string>(
    () => localStorage.getItem('multisig_tab') || 'assets',
  );
  const [
    confirmExecuteMultisigTransactionModal,
    setConfirmExecuteMultisigTransactionModal,
  ] = useState(false);
  const [multisigTransactionAsset, setMultisigTransactionAsset] =
    useState(false);
  const [transactionStatus, setTransactionStatus] = useState(undefined);
  const [theme, setTheme] = useState(
    () => localStorage.getItem('theme') || false,
  );

  useEffect(() => {
    if (theme) {
      document.body.classList.remove('theme-dark', 'theme-light');
      document.body.classList.add('theme-' + theme);
    }
  }, [theme]);

  useEffect(() => {
    const loadSettings = async () => {
      await SettingsStoreInstance.fetchAppSettings();
      setClientId(SettingsStoreInstance.appSettings.google_auth_key);
    };

    loadSettings();
  }, []);

  useEffect(() => {
    if (appToken) {
      openModal(null);
      const openUnsetModal = () => {
        openModal({
          title: 'Security Verification',
          component: () => <UnsetOtp />,
        });
      };
      void initApplication(openUnsetModal);
    } else {
      stopSync();
    }
  }, [appToken]);

  function getAccountWallet(
    accountId: number,
    options: { currencyId?: string; walletId?: number },
  ): WalletType | null {
    const { currencyId, walletId } = options;

    const account = accounts.find((item) => item.id === accountId);

    if (!account || !Array.isArray(account.wallets)) {
      return null;
    }

    const predicateFn =
      Number(walletId) > 0
        ? (item: WalletType) => item.id === walletId
        : (item: WalletType) => item.currency === currencyId;

    const wallet = account.wallets.find(predicateFn);

    return wallet || null;
  }

  const appState = {
    scrollToTop: () => {
      window?.scrollTo(0, 0);
      // @ts-ignore
      contentRef?.scrollTo(0, 0);
    },
    ref: contentRef,
    accounts,
    appSettings,
    appToken,
    checkExpiredToken: (res: Response) => {
      if (res.status === 401) {
        signOut();
      }
    },
    exchanges,
    getAccountById: getAccountById.bind(AuthStoreInstance),
    getCurrencyPrecision: getCurrencyPrecision.bind(CurrenciesStoreInstance),
    getCurrencyValue: getCurrencyValue.bind(CurrenciesStoreInstance),
    getUserInfo: fetchUserInfo.bind(UserStoreInstance),
    isConfirming,
    isEnteringOtp,
    isHideSidebar,
    rates,
    refetchAccounts: refetchData.bind(AppStoreInstance),
    setIsConfirming,
    setIsEnteringOtp,
    setIsHideSidebar,
    user,
    multisigTab,
    setMultisigTab,
    confirmExecuteMultisigTransactionModal,
    setConfirmExecuteMultisigTransactionModal,
    multisigTransactionAsset,
    setMultisigTransactionAsset,
    transactionStatus,
    setTransactionStatus,
    modal,
    openModal,
    transactionsTab,
    setTransactionsTab,
    currentAccNode,
    setCurrentAccNode,
    setStep,
    step,
    setTheme,
    theme,
    isAccountsLoading,
    setAsideMobileType,
    isAsideMobileType,
    getAccountWallet,
  };

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        openModal(null);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <AppContext.Provider value={appState}>
          <GoogleOAuthProvider clientId={clientId}>
            <GlobalStyles />
            {appToken ? (
              user ? (
                <Router>
                  <TransferProvider>
                    <AccountProvider>
                      <RouterWrap
                        contentRef={contentRef}
                        setContentRef={setContentRef}
                      />
                    </AccountProvider>
                  </TransferProvider>
                </Router>
              ) : null
            ) : (
              <>
                <Login />
                <AnimatePresence>
                  <Modal />
                </AnimatePresence>
              </>
            )}
          </GoogleOAuthProvider>
        </AppContext.Provider>
      </Web3ReactProvider>
    </QueryClientProvider>
  );
}

const C = observer(App);

export { C as App };
