import { FC, useContext, useEffect, useRef, useState } from 'react';
import { SettingsStoreInstance, UserStoreInstance } from 'services';
import { parseError } from 'shared/helpers/errors';
import { canExecuteTransaction } from 'shared/helpers/transaction';
import { ExchangeApiIdType, WalletType } from 'shared/types';

import * as S from '../Modal/styled';
import { CodeInput, digitsNumber, SubmitButton } from './shared';
import { UnsetOtp } from './UnsetOtp';
import AppContext from 'shared/contexts/AppContext';

interface CreateOtpModalProps {
  onSubmit: any;
  error: any;
  isSubmitting: any;
  exchangeName: ExchangeApiIdType | undefined;
  isTfaRequired: boolean;
  isOtpRequired: boolean;
  amount: string;
  currency: string;
  fromAccount: any;
  toAccount: any;
  fromWallet: WalletType | null;
  toWallet: WalletType | null;
}

export const CreateOtpModal: FC<CreateOtpModalProps> = ({
  onSubmit,
  isSubmitting,
  exchangeName,
  isOtpRequired,
  isTfaRequired,
  fromWallet,
  toWallet,
}) => {
  const [otpErrorText, setOtpErrorText] = useState<string | null>(null);
  const [tfaErrorText, setTfaErrorText] = useState<string | null>(null);
  const [isSubmit, setSubmit] = useState(false);
  const timerRef = useRef<number>(-1);
  const otpRef = useRef(null);
  const tfaRef = useRef(null);
  const { user } = UserStoreInstance;
  const { appSettings } = SettingsStoreInstance;
  const { openModal } = useContext(AppContext);

  useEffect(() => setSubmit(isSubmitting), [isSubmitting]);

  const [otp, setOtp] = useState('');
  const [tfa, setTfa] = useState('');

  const aboutToExecute = canExecuteTransaction(
    user?.proposal_vote_weight,
    0,
    appSettings.proposal_votes_value_to_approve,
  );

  const isOtpNotEntered = isOtpRequired && otp.length < digitsNumber;
  const isTfaNotEntered =
    isTfaRequired && aboutToExecute && tfa.length < digitsNumber;

  const disabled =
    (isSubmit || isOtpNotEntered || isTfaNotEntered) &&
    (!otpErrorText || !tfaErrorText);

  const onOtpReset = () => {
    setOtp('');
    // @ts-ignore
    otpRef?.current?.__clearvalues__();
  };

  const onTfaReset = () => {
    setTfa('');
    // @ts-ignore
    tfaRef?.current?.__clearvalues__();
  };

  const handleSubmit = async () => {
    try {
      setSubmit(true);
      await onSubmit(otp, tfa);
    } catch (error) {
      const errorText = parseError(error, { fromWallet, toWallet });
      console.log(errorText);
      if (errorText?.toUpperCase()?.includes('TFA')) {
        setTfaErrorText(errorText);
        onTfaReset();
      } else if (errorText?.toUpperCase()?.includes('OTP')) {
        setOtpErrorText(errorText);
        onOtpReset();
      } else {
        setOtpErrorText(errorText);
        openModal(null);
      }
    } finally {
      setSubmit(false);
    }
  };

  useEffect(() => {
    if (isOtpRequired || (isTfaRequired && aboutToExecute)) {
      window.clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(async () => {
        if (
          (otp.length >= digitsNumber || !isOtpRequired) &&
          (tfa.length >= digitsNumber || !(isTfaRequired && aboutToExecute))
        ) {
          handleSubmit();
        }
      }, 900);
    }
  }, [otp, tfa]);

  const onOtpChange = (vals: any) => {
    setOtp(vals);
    setSubmit(false);
    setOtpErrorText(null);
  };

  const onTfaChange = (vals: any) => {
    setTfa(vals);
    setSubmit(false);
    setTfaErrorText(null);
  };

  return (
    <S.OtpContainer
      style={{
        justifyContent:
          !isTfaRequired && !isOtpRequired ? 'right' : 'space-between',
      }}
    >
      {isOtpRequired && (
        <S.OtpWrap>
          <CodeInput
            ref={otpRef}
            value={otp}
            autoFocus={true}
            required={isOtpRequired}
            title="Multik Authenticator code"
            onChange={onOtpChange}
            onReset={onOtpReset}
            errorText={otpErrorText}
            noMargin={!(isTfaRequired && aboutToExecute)}
          />
          {otpErrorText && (
            <S.ModalError
              noMargin={!(isTfaRequired && aboutToExecute)}
              data-id="otp-error"
            >
              {otpErrorText}
            </S.ModalError>
          )}
        </S.OtpWrap>
      )}
      {isTfaRequired && aboutToExecute && (
        <S.OtpWrap>
          <CodeInput
            value={tfa}
            ref={tfaRef}
            autoFocus={!isOtpRequired}
            required={isTfaRequired && aboutToExecute}
            title={`${exchangeName} Authenticator code`}
            onChange={onTfaChange}
            onReset={onTfaReset}
            errorText={tfaErrorText}
            noMargin
          />
          {tfaErrorText && (
            <S.ModalError noMargin data-id="tfa-error">
              {tfaErrorText}
            </S.ModalError>
          )}
        </S.OtpWrap>
      )}
      {!isOtpRequired && otpErrorText && (
        <S.ModalError noMargin data-id="otp-error" style={{ width: '320px' }}>
          {otpErrorText}
        </S.ModalError>
      )}
      <S.SendButton
        type="button"
        onClick={handleSubmit}
        disabled={disabled}
        style={{
          alignSelf: otpErrorText || tfaErrorText ? 'flex-start' : 'self-end',
          marginTop: tfaErrorText || otpErrorText ? '20px' : '0',
        }}
      >
        <SubmitButton
          isSubmit={isSubmit}
          errorText={otpErrorText || tfaErrorText}
        />
      </S.SendButton>
    </S.OtpContainer>
  );
};
