import { action, makeObservable, observable, runInAction } from 'mobx';

import { requestGet } from 'shared/axios';

import { CurrencyType, isCurrencyFiat } from 'shared/types';
import { getCurrencyValue as getCurValue } from 'shared/helpers/currencies';
import { SettingsStoreInstance } from 'services';

import { ICurrenciesStore } from '../models';

class CurrenciesStore implements ICurrenciesStore {
  constructor() {
    makeObservable(this);
  }

  @observable cryptoCurrencies = [];
  @observable rates: Nullable<any> = null;

  public isCurrencyFiat = () => {
    return false;
  };

  @action.bound
  public getCurrencyPrecision(currency?: CurrencyType): number {
    const { appSettings } = SettingsStoreInstance;
    const defaultPrecision = 0;

    const currencyName = currency?.toLowerCase(); // todo: temporary fix for uppercase coin names

    if (!currency || !currencyName) {
      return defaultPrecision;
    }

    if (!appSettings.overview_decimals) {
      return defaultPrecision;
    }

    const isFiat = isCurrencyFiat(currency);

    return appSettings.overview_decimals[currencyName]
      ? appSettings.overview_decimals[currencyName]
      : appSettings.overview_decimals.default[
      currencyName === 'usd_k' || currencyName === 'usd_m'
        ? currencyName
        : isFiat
          ? 'fiat'
          : 'crypto'
      ] ?? defaultPrecision;
  }

  @action.bound
  public getCurrencyValue({
      currency,
      value,
      precision,
      minprecision,
    }: {
    currency: CurrencyType | undefined;
    value: string;
    precision?: number;
    minprecision?: number;
  }) {
    const apiPrecision =
      currency !== undefined ? this.getCurrencyPrecision(currency) : undefined;

    return getCurValue({
      currency,
      value,
      precision: precision || apiPrecision,
      ...(minprecision !== undefined && { minprecision }),
    });
  }

  @action.bound public async fetchRates() {
    try {
      const response = await requestGet('/rates/');
      if (response.status >= 200) {
        runInAction(() => {
          this.rates = response.data;
        });
      }
    } catch (e) {
      this.rates = {};
      console.log('get rates error:', e);
    }
  }
}

export const CurrenciesStoreInstance = new CurrenciesStore();
