import { useState, useEffect, useContext, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import styled, { css } from 'styled-components/macro';
import { motion } from 'framer-motion';
import AppContext from 'shared/contexts/AppContext';
import { CurrencyType } from 'shared/types';
import { getCoinsInfoById } from 'shared/constants/coins';

import Circle from 'shared/components/Сircle';

import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-chevron-down.svg';

const SelectWrap = styled.div`
  padding: 0;
  width: 100%;
  border: 1px solid var(--border);
  border-radius: 5px;
  margin: 0 0 20px 0;
  position: relative;
  display: flex;
  align-items: center;
`;

const SelectDropdown = styled.div`
  position: absolute;
  left: -1px;
  right: -1px;
  top: 100%;
  background: var(--base);
  display: flex;
  flex-direction: column;
  z-index: 2;
  border: 1px solid var(--border);
  max-height: 200px;
  overflow: auto;
  border-radius: 5px;
  z-index: 99;
`;
const Title = styled.div`
  font-size: 12px;
  margin: 0 5px 0 0;
`;
const Addres = styled.div`
  font-size: 12px;
  font-weight: 700;
`;
const Asset = styled.span`
  text-transform: uppercase;
`;
const SelectItem = styled.div<{ active?: boolean }>`
  padding: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    background-color: var(--highlight);
  }
  ${(props) =>
    props.active &&
    css`
      background-color: var(--accent-primary);
      color: var(--base);

      ${Title}, ${Addres}, ${Asset} {
        color: var(--base);
      }
      &:hover {
        background-color: var(--accent-primary);
      }
    `};
`;
const IconDropdown = styled(SvgArrow)`
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  stroke: var(--object-secondary);
  display: inline-block;
`;
const SelectItemDefault = styled(SelectItem)`
  &:hover {
    background: none;
  }
`;
const SelectItemWrap = styled.div`
  padding: 0 0 0 10px;
  display: flex;
  align-items: center;
`;
const Placeholder = styled.span`
  line-height: 20px;
  opacity: 0.5;
`;

function SelectAsset(props: any) {
  const { multisigTransactionAsset, setMultisigTransactionAsset } =
    useContext(AppContext);
  const [defaultValue, setDefaultValue] = useState(
    props.default || multisigTransactionAsset.asset,
  );
  const [data, setData] = useState(props.data || []);

  // reset selected asset
  useEffect(() => () => setMultisigTransactionAsset(false), []);

  function getAsset(id: string): any | undefined {
    // if (typeof id?.toLowerCase !== 'function') {
    //   return;
    // }
    return data.find(
      (item: any) => item.asset?.toLowerCase() === id?.toLowerCase(),
    );
  }
  const [selected, setSelected] = useState(
    defaultValue ? getAsset(defaultValue) : false,
  );
  const [dropDown, setDropDown] = useState(false);
  const selectRef = useRef(null);
  useOnClickOutside(selectRef, () => setDropDown(false));

  function handleSetActive(item: any) {
    setDefaultValue(item.asset);
    setMultisigTransactionAsset(item);
    setSelected(getAsset(item.asset));
  }

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <SelectWrap onClick={() => setDropDown(!dropDown)} ref={selectRef}>
      <SelectItemDefault>
        {selected ? (
          <>
            {getCoinsInfoById(selected?.asset)?.logo_url ? (
              <Circle
                size={20}
                image={getCoinsInfoById(selected.asset)?.logo_url}
              />
            ) : null}
            <SelectItemWrap>
              {selected.name ? <Title>{selected.name}</Title> : null}
              {selected.address ? <Addres>{selected.address}</Addres> : null}
              {selected.value ? <Addres>{selected.value}</Addres> : null}
            </SelectItemWrap>
          </>
        ) : (
          <Placeholder>Select</Placeholder>
        )}
      </SelectItemDefault>
      {data?.length ? (
        <>
          <IconDropdown />
          {dropDown ? (
            <SelectDropdown>
              {data.map((item: any, idx: number) => {
                return (
                  <SelectItem
                    active={defaultValue === item.asset}
                    key={idx}
                    onClick={() => handleSetActive(item)}
                  >
                    {getCoinsInfoById(item.asset)?.logo_url ? (
                      <Circle
                        size={20}
                        image={getCoinsInfoById(item.asset)?.logo_url}
                      />
                    ) : null}
                    <SelectItemWrap>
                      {item.name ? <Title>{item.name}</Title> : null}
                      {item.value ? (
                        <Addres>
                          {item.value} <Asset>{item.asset}</Asset>
                        </Addres>
                      ) : null}
                    </SelectItemWrap>
                  </SelectItem>
                );
              })}
            </SelectDropdown>
          ) : null}
        </>
      ) : null}
    </SelectWrap>
  );
}
export default SelectAsset;
