import {
  AccountType,
  UserInfoType,
  WalletType,
  Thresholds,
  Threshold,
} from 'shared/types';
import { CROSS_WALLET_SUPPORTED } from './constants';
import { TransferParticipant } from './types';
import {
  aboutToExceedLimit,
  aboutToExceedSingleLimit,
} from 'shared/helpers/transaction';
import { formatAmountPrecise } from 'features/Multisig/helpers';

export const checkIsFromWallet = (from?: TransferParticipant) => {
  return from?.account && from?.account?.exchange === 'WALLETS';
};

export const checkIsFromMultisig = (from?: TransferParticipant) => {
  return checkIsFromWallet(from) && from?.account?.is_multisig;
};

export const checkIsCrossWallet = (
  from?: TransferParticipant,
  to?: TransferParticipant,
) => {
  return (
    from?.account?.id === to?.account?.id &&
    [from?.account?.exchange, to?.account?.exchange].every(
      (exchange) => exchange && CROSS_WALLET_SUPPORTED.includes(exchange),
    )
  );
};

export const checkAreBothFutures = (
  from?: TransferParticipant,
  to?: TransferParticipant,
) => {
  return [from?.wallet?.type, to?.wallet?.type].every((type) =>
    type?.includes('futures'),
  );
};

export const checkIsDepositAddressWhitelisted = (wallet?: WalletType) => {
  return wallet?.isWhitelisted;
};

export const checkIsShowFees = (
  isInternal: boolean,
  isFromWallet?: boolean,
  minWithdrawLimit?: string | null,
  withdrawFee?: string | null,
  depositAddressIsWhitelisted?: boolean,
) => {
  return Boolean(
    depositAddressIsWhitelisted &&
      !isFromWallet &&
      !isInternal &&
      (minWithdrawLimit || withdrawFee),
  );
};

export const checkIsInternal = (
  from?: TransferParticipant,
  to?: TransferParticipant,
) => {
  return from?.account?.id === to?.account?.id;
};

export const getWithdrawFee = (from?: TransferParticipant) => {
  return from?.account?.exchange_infos?.find(
    (info) => from?.wallet?.currency === info.currency && info.name === 'fee',
  )?.min_value;
};

export const getMinWithdrawLimit = (from?: TransferParticipant) => {
  return from?.account?.exchange_infos?.find(
    (info) =>
      from?.wallet?.currency === info.currency && info.name === 'withdrawal',
  )?.min_value;
};

export const checkIsInsufficientFunds = (
  amount: number,
  from?: TransferParticipant,
) => {
  return amount > 0 && (Number(from?.wallet?.available) || 0) < amount;
};

export const getTransferError = (
  amount: number,
  rates: any,
  user: UserInfoType | null,
  from?: TransferParticipant,
  to?: TransferParticipant,
) => {
  const insufficientFunds = checkIsInsufficientFunds(amount, from);
  const exceedsLimit = aboutToExceedLimit(
    amount,
    from?.wallet?.currency,
    user,
    rates,
  );
  const exceedsSingleLimit = aboutToExceedSingleLimit(
    amount,
    from?.wallet?.currency,
    user,
    rates,
  );

  if (insufficientFunds) {
    return 'Insufficient funds';
  } else if (exceedsLimit) {
    return 'The amount exceeds the limit';
  } else if (exceedsSingleLimit) {
    return 'The amount exceeds the single transaction limit';
  }
};

export const getViolatedCurrencies = (account: AccountType) => {
  const thresholds = account.thresholds;
  const totalThreshold = thresholds?.total;
  const specificThreshold = thresholds?.specific;

  if (!totalThreshold && !specificThreshold) return [];

  const allThresholds = { ...totalThreshold, ...specificThreshold };
  const thresholdCurrencies = [
    // @ts-ignore
    ...Object.keys(totalThreshold),
    // @ts-ignore
    ...Object.keys(specificThreshold),
  ];

  return thresholdCurrencies.filter(
    // @ts-ignore
    (curr) => allThresholds[curr].is_violated,
  );
};

export function formatAmount(
  value: number,
  minAmount: number,
  maxAmount: number,
): number {
  return Math.min(Math.max(value, minAmount), maxAmount);
}

export function calculateCurrentThreshold(threshold: Threshold): number {
  const { min_amount, max_amount, current_amount } = threshold;
  if (current_amount < min_amount) {
    return min_amount;
  } else if (current_amount > max_amount) {
    return max_amount;
  } else {
    return current_amount;
  }
}

export function generateThresholdExceededMessage(
  thresholds: Thresholds,
): string {
  const totalThreshold = thresholds.total && thresholds.total.USD;
  const specificThresholds = Object.fromEntries(
    Object.entries(thresholds.specific).filter(
      ([, value]) => value?.is_violated,
    ),
  );

  const totalMessage = totalThreshold
    ? `The total amount (${totalThreshold.current_amount} USD)`
    : '';

  const specificMessages = Object.entries(specificThresholds).map(
    ([asset, threshold]) => {
      const currentAmount = threshold && threshold?.current_amount;
      return `${asset} (${currentAmount} ${asset})`;
    },
  );

  const endThresholdMessages = Object.entries(specificThresholds).map(
    ([asset, threshold], index, arr) => {
      if (threshold) {
        const formattedAmount = calculateCurrentThreshold(threshold);

        const dynamicAnd =
          totalThreshold && Object.entries(specificThresholds)?.length > 0
            ? 'and'
            : '';

        const singleRes = ` ${dynamicAnd} ${asset} threshold (${formattedAmount} ${asset})`;

        const dynamicComma =
          !totalThreshold && arr.length > 1 && index === 0 ? '' : ',';

        const andOrComma =
          index === 0 && totalThreshold ? ' and' : dynamicComma;

        const lastString = arr.length - 1 === index ? ' thresholds' : '';

        return arr.length === 1
          ? singleRes
          : `${andOrComma} ${asset} (${formattedAmount} ${asset})${lastString}`;
      }
      return '';
    },
  );

  let message = `${totalMessage}${
    totalMessage && specificMessages.length > 0 ? ' and the' : ''
  }${
    specificMessages.length > 0
      ? `${
          totalMessage && specificMessages.length > 0 ? '' : 'The'
        } total value of ` + specificMessages.join(', ')
      : ''
  }${totalMessage ? ' in your account' : ' in your account has exceeded'}`;

  if (totalThreshold) {
    const formattedTotalAmount = formatAmount(
      totalThreshold.current_amount,
      totalThreshold.min_amount,
      totalThreshold.max_amount,
    );

    message += ` has exceeded the total threshold (${formatAmountPrecise(
      formattedTotalAmount,
      8,
    )} USD)`;
  }

  if (endThresholdMessages.length > 0) {
    message += `${endThresholdMessages.join('')}`;
  }

  return message;
}
