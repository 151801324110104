import { useEffect } from 'react';
import { EIP1193Provider, WalletState, OnboardAPI } from '@web3-onboard/core';
import { ChainInfo } from '@gnosis.pm/safe-react-gateway-sdk';
import { getAddress } from 'ethers/lib/utils';
import ExternalStore from '../helpers/ExternalStore';
import { localItem } from '../local-storage/local';
import { Errors, logError } from 'shared/helpers/exceptions';
import { WALLET_KEYS } from '../constants/wallets';
import { isWalletUnlocked, WalletNames } from '../helpers/wallets';
import { SafeStoreInstance } from 'features/Multisig/mobx/SafeStore';

export type ConnectedWallet = {
  name: string
  chainId: string
  address: string
  ens?: string
  provider: EIP1193Provider
};

export const lastWalletStorage = localItem<string>('lastWallet');

const { getStore, setStore, useStore } = new ExternalStore<OnboardAPI>();

export const initOnboard = async (chainConfigs: ChainInfo[]) => {
  const { createOnboard } = await import('../helpers/onboard');
  if (!getStore()) {
    setStore(createOnboard(chainConfigs));
  }
};

// Get the most recently connected wallet address
export const getConnectedWallet = (wallets: WalletState[]): ConnectedWallet | null => {
  if (!wallets) return null;

  const primaryWallet = wallets[0];
  if (!primaryWallet) return null;

  const account = primaryWallet?.accounts[0];
  if (!account) return null;

  return {
    name: primaryWallet.label,
    address: getAddress(account.address),
    ens: account.ens?.name,
    chainId: Number(primaryWallet.chains[0].id).toString(10),
    provider: primaryWallet.provider,
  };
};

const getWalletConnectLabel = async ({ name, provider }: ConnectedWallet): Promise<string | undefined> => {
  if (name.toUpperCase() !== WALLET_KEYS.WALLETCONNECT.toUpperCase()) return;

  const UNKNOWN_PEER = 'Unknown';
  const { default: WalletConnect } = await import('@walletconnect/client');

  const peerWallet =
    ((provider as unknown as any).connector as InstanceType<typeof WalletConnect>).peerMeta?.name || UNKNOWN_PEER;

  return peerWallet ?? UNKNOWN_PEER;
};

// Detect mobile devices
const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

// Wrapper that tracks/sets the last used wallet
export const connectWallet = (onboard: OnboardAPI, options?: Parameters<OnboardAPI['connectWallet']>[0]) => {
  // On mobile, automatically choose WalletConnect
  if (!options && isMobile()) {
    options = {
      autoSelect: WalletNames.WALLET_CONNECT,
    };
  }

  return onboard
    .connectWallet(options)
    .then(async (wallets) => {
      const newWallet = getConnectedWallet(wallets);

      if (newWallet) {
        lastWalletStorage.set(newWallet.name);
        return newWallet;
      }
    })
    .catch((e) => logError(Errors._302, (e as Error).message));
};

// Disable/enable wallets according to chain and cache the last used wallet
export const useInitOnboard = () => {
  const { chains, selectedChain  } = SafeStoreInstance;
  const onboard = useStore();

  useEffect(() => {
    if (chains && chains.length > 0) {
      initOnboard(chains);
    }
  }, [chains]);

  // Disable unsupported wallets on the current chain
  useEffect(() => {
    if (!onboard || !selectedChain) return;

    const enableWallets = async () => {
      const { getSupportedWallets } = await import('../constants/wallets');
      const supportedWallets = getSupportedWallets(selectedChain);
      onboard.state.actions.setWalletModules(supportedWallets);
    };

    enableWallets();
  }, [selectedChain, onboard]);

  // Connect to the last connected wallet
  useEffect(() => {
    if (onboard && onboard.state.get().wallets.length === 0) {
      const label = lastWalletStorage.get();
      if (!label) return;

      isWalletUnlocked(label).then((isUnlocked) => {
        isUnlocked &&
          connectWallet(onboard, {
            autoSelect: { label, disableModals: true },
          });
      });
    }
  }, [onboard]);
};

export default useStore;
