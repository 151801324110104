const Articles = [
  {
    title: 'Exchange API keys settings',
    category: 'exchange',
    description:
      'Here are instructions for setting up API access for supported exchanges to work with the Multik platform.',
    url: 'https://exantech.atlassian.net/wiki/spaces/CH/pages/50528307/Exchange+API+keys+settings',
  },
  {
    title: 'Bitfinex  ',
    category: 'exchange',
    description:
      'To set up the Bitfinex exchange to work with the Multik platform, you need to complete the following steps.',
    url: 'https://exantech.atlassian.net/wiki/spaces/CH/pages/31850497',
  },
  {
    title: 'Deribit ',
    category: 'exchange',
    description:
      'To set up the Deribit exchange to work with the Multik platform, you need to complete the following steps.',
    url: 'https://exantech.atlassian.net/wiki/spaces/CH/pages/45776897',
  },
  {
    title: 'Kraken ',
    category: 'exchange',
    description:
      'To set up the Kraken exchange to work with the Multik platform, you need to complete the following steps.',
    url: 'https://exantech.atlassian.net/wiki/spaces/CH/pages/46137383',
  },
  {
    title: 'Bitstamp',
    category: 'exchange',
    description:
      'To set up the Bitstamp exchange to work with the Multik platform, you need to complete the following steps.',
    url: 'https://exantech.atlassian.net/wiki/spaces/CH/pages/47644704',
  },
  {
    title: 'HitBTC',
    category: 'exchange',
    description:
      'To set up the HitBTC exchange to work with the Multik platform, you need to complete the following steps.',
    url: 'https://exantech.atlassian.net/wiki/spaces/CH/pages/49741825',
  },
  {
    title: 'Okcoin',
    category: 'exchange',
    description:
      'To set up the Okcoin exchange to work with the Multik platform, you need to complete the following steps.',
    url: 'https://exantech.atlassian.net/wiki/spaces/CH/pages/49905665',
  },
  {
    title: 'Binance',
    category: 'exchange',
    description:
      'To set up the Binance exchange to work with the Multik platform, you need to complete the following steps.',
    url: 'https://exantech.atlassian.net/wiki/spaces/CH/pages/49807375',
  },
  {
    title: 'dYdX',
    category: 'exchange',
    description:
      'To set up the dYdX exchange to work with the Multik platform, you need to complete the following steps.',
    url: 'https://exantech.atlassian.net/wiki/spaces/CH/pages/49774617',
  },
  {
    title: 'Four-eyes confirmation logic',
    category: 'other',
    description:
      'We added a new proposal functionality, which we call Four-eyes confirmation.',
    url: 'https://exantech.atlassian.net/wiki/spaces/CH/pages/33816577/Four-eyes+confirmation+logic',
  },
  {
    title:
      'Adding addresses to the Whitelist',
    category: 'other',
    description:
      'To add the address to the Whitelist, you need to complete the following steps in Multik Provisioning App.',
    url: 'https://exantech.atlassian.net/wiki/spaces/CH/pages/88277012/Adding+addresses+to+the+Whitelist+using+the+Multik+Provisioning+mobile+app',
  },
];

export default Articles;
