import { useQuery } from '@tanstack/react-query';
import { FC, MouseEventHandler, useContext, useState } from 'react';
import { UserStoreInstance } from 'services';
import { API_URL } from 'shared/constants/app';
import AppContext from 'shared/contexts/AppContext';
import { CardSetValue } from './CardSetValue';
import { QrModal } from './QrModal';
import queryString from 'query-string';

import * as S from '../styled';

const getQrCode = async () => {
  const response = await fetch(`${API_URL}/auth/hotp/`, {
    method: 'POST',
    headers: {
      authorization: `Token ${localStorage.getItem('appToken')}`,
    },
  });

  return await response.json();
};

interface OtpCardProps {
  has_hotp: boolean;
}

export const OtpCard: FC<OtpCardProps> = ({ has_hotp }) => {
  const { fetchUserInfo } = UserStoreInstance;
  const [error, setError] = useState(false);
  const { openModal } = useContext(AppContext);
  const { refetch } = useQuery({
    queryKey: ['hotp-code'],
    queryFn: getQrCode,
    enabled: false,
    onSuccess: (data) => {
      if (!data.error) {
        const extracted = queryString.extract(data.secret);
        const parts = queryString.parse(extracted);
        openModal({
          title: 'Get QR Code',
          component: () => (
            <QrModal
              encoded={data.secret}
              decoded={String(parts.secret)}
              comment={
                <>
                  Scan QR code with Google Authenticator <br />
                  or type in the text code.
                </>
              }
            />
          ),
        });
        fetchUserInfo();
      } else {
        setError(true);
      }
    },
  });

  const onClick: MouseEventHandler<HTMLElement> = (e) => {
    setError(false);
    refetch();
  };

  const Value = () => {
    if (error) {
      return (
        <S.ErrorText>
          To reset your OTP code please contact us at{' '}
          <a href="mailto:support@multik.io">support@multik.io</a>
        </S.ErrorText>
      );
    }

    if (has_hotp) {
      return <S.OtpButton onClick={onClick}>reset qr code</S.OtpButton>;
    } else {
      return <S.OtpButton onClick={onClick}>get qr code</S.OtpButton>;
    }
  };

  return (
    <S.CardWrapper>
      <S.CardHeader>
        <S.CardHeaderText>
          <S.IconLock />
          OTP: Google Authenticator
        </S.CardHeaderText>
        <CardSetValue value={has_hotp} />
      </S.CardHeader>
      <S.CardValue>
        <Value />
      </S.CardValue>
    </S.CardWrapper>
  );
};
